import React from "react";
import GoogleMapReact from "google-map-react";
import LocationPin from "./locationpin";
import "./map.css";

const Googlemap = ({ location, zoomLevel }) => {
  return (
    <>
      <h2 className="map-h2">Come Visit Us At Our Campus</h2>

      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyAI2Jc0LEvGW1LwaXD49hPL9bTk0uIKwR8",
            language: "en",
          }}
          defaultCenter={location}
          defaultZoom={zoomLevel}
        >
          <LocationPin
            lat={location.lat}
            lng={location.lng}
            text={location.address}
          />
        </GoogleMapReact>
      </div>
    </>
  );
};

export default Googlemap;

import "./App.css";
import { useUserState } from "./context/auth.context";
import React from "react";
import { useState, useEffect, useMemo } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/login";
import Homepage from "../src/pages/homepage/index";
import Layout from "./component/Layout/index";
import SignUP from "../src/pages/SignUP/index";
import Notification from "./component/notifications";
import Signuprestro from "../src/pages/signup-restaurent/index";
import Resetpassword from "../src/pages/resetpage";
import { requestForToken } from "../src/pages/firebase";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  var { isAuthenticated } = useUserState();

  useMemo(() => {
    requestForToken();
  }, []);

  return (
    <>
      <div className="App">
        <Notification />
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              exact
              name="Login"
              element={
                isAuthenticated ? <Layout></Layout> : <Homepage></Homepage>
              }
            ></Route>
            <Route
              path="/signup/:id"
              exact
              name="Signup"
              element={isAuthenticated ? <Layout></Layout> : <SignUP></SignUP>}
            ></Route>

            <Route
              path="/sign-up/:id"
              exact
              name="Signup"
              element={
                isAuthenticated ? (
                  <Layout></Layout>
                ) : (
                  <Signuprestro></Signuprestro>
                )
              }
            />
            <Route
              path="/resetpassword"
              exact
              name="Resetpassword"
              element={
                isAuthenticated ? (
                  <Layout></Layout>
                ) : (
                  <Resetpassword></Resetpassword>
                )
              }
            ></Route>

            <Route
              exact
              path="*"
              name="Home"
              element={
                isAuthenticated ? <Layout /> : <Navigate to="/" replace />
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;

import Banner from "../../component/banner/index";
import ClientSlider from "../../component/client-slider";
import MemberTab from "../../component/member-tab";
import TrustedSection from "../../component/trusted-section";
import Header from "../../component/Header/index";
import Footer from "../../component/Footer/index";
// import Help from "../../component/help/helpmodel";
import InnerHeader from "../../component/header";
import { decryptData } from "../../helper";
import { useState, useMemo } from "react";

export default function Home() {
  const [visible, setVisible] = useState(false);
  const [userid, setuserid] = useState("");
  useMemo(() => {
    decryptData().then((response) => {
      if (response) {
        setuserid(response?._id);
      }
    });
  }, [userid]);

  return (
    <>
      <div className="row">{userid ? <InnerHeader /> : <Header />}</div>
      <div>
        {/* <Help visible={visible} setVisible={setVisible}/>
    <button onClick={()=>{setVisible(true)}}></button> */}
        <Banner></Banner>
        <section className="pT50 pB50">
          <div className="container">
            <h1 className="headings">
              Our Featured <span>Members</span>
            </h1>
            <MemberTab></MemberTab>
          </div>
        </section>
        <TrustedSection></TrustedSection>
        <section className="pT50 pB50">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-lg-6">
                <div className="workMain">
                  <h1 className="headingss">
                    How It <span>Works?</span>
                  </h1>
                  <div className="stepsMain">
                    <div className="stepbox">
                      <span>01</span>
                      <div>
                        <h3>FIND</h3>
                        <p>
                          We&apos;ll quickly match you with the right
                          freelancers.
                        </p>
                      </div>
                    </div>
                    <div className="stepbox">
                      <span>02</span>
                      <div>
                        <h3>HIRE</h3>
                        <p>
                          We&apos;ll quickly match you with the right
                          freelancers.
                        </p>
                      </div>
                    </div>
                    <div className="stepbox">
                      <span>03</span>
                      <div>
                        <h3>WORK</h3>
                        <p>
                          We&apos;ll quickly match you with the right
                          freelancers.
                        </p>
                      </div>
                    </div>
                    <div className="stepbox">
                      <span>04</span>
                      <div>
                        <h3>PAY</h3>
                        <p>
                          We&apos;ll quickly match you with the right
                          freelancers.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="col-sm-6 col-lg-6 ">
                <div className="workMain">
                  <h1 className="headingsse">FAQ</h1>
                  <div className="stepsMain">
                    <div className="stepbox">
                      <span>01</span>
                      <div>
                        <h3>FIND</h3>
                        <p>
                          We&apos;ll quickly match you with the right
                          freelancers.
                        </p>
                      </div>
                    </div>
                    <div className="stepbox">
                      <span>02</span>
                      <div>
                        <h3>HIRE</h3>
                        <p>
                          We&apos;ll quickly match you with the right
                          freelancers.
                        </p>
                      </div>
                    </div>
                    <div className="stepbox">
                      <span>03</span>
                      <div>
                        <h3>WORK</h3>
                        <p>
                          We&apos;ll quickly match you with the right
                          freelancers.
                        </p>
                      </div>
                    </div>
                    <div className="stepbox">
                      <span>04</span>
                      <div>
                        <h3>PAY</h3>
                        <p>
                          We&apos;ll quickly match you with the right
                          freelancers.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/* <div className="col-lg-7 col-sm-6">
                <div className="workMain">
                  <h1 className="headingsss">
                    Collect Free <span>Sample</span>
                  </h1>
                  <div className="collect-sample-form">
                    <div className="chef-from-img">
                      <img src="images/male-chef-3.png" />
                    </div>
                    <form method="post">
                      <div className="form-heads">
                        Collect your free sample fillout this form.
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            value=""
                            name=""
                            placeholder="Your Name"
                            className="customInput"
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            value=""
                            name=""
                            placeholder="Your Name"
                            className="customInput"
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            value=""
                            name=""
                            placeholder="Your Name"
                            className="customInput"
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            value=""
                            name=""
                            placeholder="Your Name"
                            className="customInput"
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            value=""
                            name=""
                            placeholder="Your Name"
                            className="customInput"
                          />
                        </li>
                        <li>
                          <button>Submit</button>
                        </li>
                      </ul>
                    </form>
                  </div>
                  <div className="form-footer">
                    <p>
                      We help all food professional create an online presence
                      and portfolio for thier food business. Discover Local Food
                      Professionls near you anywhere in the World
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <ClientSlider></ClientSlider>
        <section
          className="onlineMain
      "
        >
          <div className="container">
            <h1 className="heading">
              <span>Build</span> Your Online System
            </h1>

            <div className="row">
              <div className="col-sm-4">
                <div className="landing-post-item">
                  <div className="post-image">
                    <img src="images/custtaster.png" />
                  </div>

                  <h1>Chefs &amp; Cooks</h1>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="landing-post-item">
                  <div className="post-image">
                    <img src="images/Grocery_1.png" />
                  </div>

                  <h1>Groceries &amp; Convenience Store</h1>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="landing-post-item">
                  <div className="post-image">
                    <img src="images/custtaster.png" />
                  </div>

                  <h1>Customers &amp; Foodies</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div>
        <Footer></Footer>
      </div>
    </>
  );
}

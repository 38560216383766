// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCLC4wGtb6uJjilF7iHDUjGohR8YHkVqfU",
  authDomain: "electrostreamtech-ac7cc.firebaseapp.com",
  projectId: "electrostreamtech-ac7cc",
  storageBucket: "electrostreamtech-ac7cc.appspot.com",
  messagingSenderId: "252515148149",
  appId: "1:252515148149:web:5723901c3e032b184df33c",
  measurementId: "G-ZVBNG73N0G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const messaging = getMessaging();

export const requestForToken = () => {
  getToken(messaging, {
    vapidKey:
      "BBpp9Zh7r8wSoRGa-20SA5T9yHnWjDgpGXb51CkVbLQ6z6csAHGTp8axi53TFh8pKDNVNe1Dm4PKP4uxClKXGmc",
  })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem("fcm_token", currentToken);
      } else {
        // Show permission request UI
        // console.log(
        //   "No registration token available. Request permission to generate one."
        // );
        toast.info(
          "Please allow browser notifications to access notifications popup. "
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

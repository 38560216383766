let appMode = process.env.REACT_APP_APP_MODE;
let prodURL = process.env.REACT_APP_PROD_URL;
let devURL = process.env.REACT_APP_DEV_URL;

let URL = appMode === "production" ? prodURL : devURL;

let pathApiObj = {
  BASE_URL: URL,

  // authentication
  LOGIN: "auth/login",
  SIGNUP: "auth/signup",
  SIGNUPRESTRO: "users/restaurent-signup",
  forgetpassword: "auth/forgot-password",
  resetpassword: "auth/reset-password",
  checkpassword: "auth/token-check",

  // chef-module
  TypeDropdown: "master-table/dropdown_type",
  CusiniesDropdown: "master-table/dropdown_cuisines",
  SpiceLevelDropdown: "master-table/dropdown_spicelevel",
  CusinieDropdown: "master-table/dropdown_cuisine",
  AddDish: "dishes/create",
  AboutMe: "about-me/list",
  MasterTableList: "master-table/list",
  AddProduct: "my-store/create",
  DishList: "dishes/list",
  GetAddDishList: "dishes/list",
  GetStoreList: "my-store/list",
  Deleteadishlist: "dishes/delete",
  GETDETAIL: "dishes/detail",
  UpdateDish: "dishes/update",
  Changepassword: "auth/change-password",
  UserLikelist: "livefeed/list/userlike",

  // cook/mystore
  ListStore: "my-store/list",
  UpdateStore: "my-store/update",
  Deletestore: "my-store/delete",
  Addstorelist: "my-store/create",
  Detailstorellist: "my-store/detail",

  //faq-module
  Addfaq: "faq/create",
  UpdateFaQ: "faq/update",
  Faqlist: "faq/list",
  deletefaq: "faq/delete",
  UpdateLiveFeed: "livefeed/update",
  FAQDETAIL: "faq/detail",
  deleteCart: "add-cart/delete",
  AddFaQList: " faq/list",
  AddService: "other-services/list",

  //About Data
  AddAbout: "about-me/create",
  Aboutlist: "about-me/list",
  UpdateAbout_me: "about-me/update",
  GETAboutDETAIL: "about-me/detail",
  UpdatePhoto: "gallery/create",
  Gallerylist: "gallery/list",

  //Feedback

  feedbacklist: "feedback/list",
  Addfeedback: "",
  Orderdetail: "orders/detail",
  AddRating: "rating/create",

  //Distance unit

  ADDDistance: "distance-unit/create",

  //Services
  AddSercvice: "other-services/create",

  // drivers
  GET_Listdriver: "drivers/list",

  //fvouritedish
  FVOURITEDISH: "favourite-dish/create",
  // Orderlist
  ORDERLIST: "orders/list",
  ORDERDETAIL: "orders/detail",
  CARTDETAIL: "add-cart/detail",
  // ShopNOrder
  ShopNOrderLIST: "",
  ShopNOrderDETAIL: "",
  UPLOAD: "util/upload-file",

  // dashboard
  FeedList: "livefeed/list",
  // Taster-dashboard
  Addrequest: "request-sample/create",
  AddCartDetail: "add-cart/create",
  DISHDETAIL: "dishes/detail",
  cartlist: "add-cart/list",
  UpdateCart: "add-cart/update",
  UserLikelist: "livefeed//list/userlike",
  checkoutform: "cart-checkout/create",
  createOrder: "orders/create",
  EventorderDETAIL: "event-orders/detail",
  createeventOrder: "event-orders/create",
  Updatechefinvite: "event-orders/update",
  Featuredmanlist: "",
  EventOrderlist: "event-orders/list",
  Featuredwomanlist: "users/list",
  Recentallyaddedlist: "",
  Followers_list: "followers-following/list",
  approvedstatus: "followers-following/approved_status",
  Fav_dishes: "favourite-dish/list",
  Extraserviceslist: "extra-services/list",

  // common-services
  countrylist: "country/list",
  PaymentIntent: "util/create-intent",
  userlist: "users/list",
  //grocery
  Creategrocery: "grocery/create",
  GET_GroceryCardlist: "grocery/list",
  Deletegrolist: "grocery/delete",
  driver_list: "search_driver",
  updategrolist: "grocery/update",
  GroDetail: "grocery/detail",
};

export default pathApiObj;

import { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CustomerSlider from "../customer-slider";
import MemberBox from "../member-box";
import MemberSlider from "../member-slider";

const MemberTab = ({ variant }) => {
  const [key, setKey] = useState("customer");

  return (
    <div className="memberTabMain">
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="customer" title="Customer">
          <div className="memberTabIn">
            <CustomerSlider></CustomerSlider>
          </div>
        </Tab>
        <Tab eventKey="grocery" title="Grocery">
          <div className="memberTabIn">
            <CustomerSlider></CustomerSlider>
          </div>
        </Tab>
        <Tab eventKey="chef" title="Chef">
          <div className="memberTabIn">
            <CustomerSlider></CustomerSlider>
          </div>
        </Tab>
        <Tab eventKey="delivery-driver" title="Delivery Driver">
          <div className="memberTabIn">
            <CustomerSlider></CustomerSlider>
          </div>
        </Tab>
        <Tab eventKey="restaurants" title="Restaurants">
          <div className="memberTabIn">
            <CustomerSlider></CustomerSlider>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};
export default MemberTab;

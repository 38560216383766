import { useState, useEffect, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Signupmodel from "../component/sign-upmodel/index";
import OffcanvasModal from "../component/offcanvas-checkout/index";
import styles from "../styles/modules/header.module.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { decryptData } from "../helper";
import { useUserDispatch } from "../context/auth.context";
import { useNavigate } from "react-router-dom";
// import Login from "../login";

const InnerHeader = ({ variant }) => {
  const [show, setShow] = useState(false);
  const [userdata, setuserdata] = useState("");
  const [visible, setVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [appear, setAppear] = useState(false);
  const history = useNavigate();
  const handleClose = () => setAppear(false);
  const handleShow = () => setAppear(true);
  const [role, setrole] = useState();
  useMemo(() => {
    decryptData().then((response) => {
      if (response) {
        setrole(response?.role?._id);
        setuserdata(response);
      }
    });
  }, []);

  const userDispatch = useUserDispatch();
  const Logoutfn = (e) => {
    e.preventDefault();
    sessionStorage.clear();
    userDispatch({ type: "SIGN_OUT_SUCCESS" });
  };

  const profilefn = (e) => {
    e.preventDefault();
    if (role == "640ec4c5941ddd210c4dae93") {
      history(`/chef-profile`);
    }
    if (role == "640ec4da941ddd210c4dae98") {
      history(`/driver-profile`);
    }
    if (role == "640ec4ec941ddd210c4dae9d") {
      history(`/taster-profile`);
    }
    if (role == "640ec508941ddd210c4daea7") {
      history(`/grocery-myprofile`);
    }
    if (role == "640ec4f7941ddd210c4daea2") {
      history(`/restaurent-profile`);
    }
  };
  const settingfn = (e) => {
    e.preventDefault();
    if (role == "640ec4c5941ddd210c4dae93") {
      history(`/chef-setting`);
    }
    if (role == "640ec4da941ddd210c4dae98") {
      history(`/driver-setting`);
    }
    if (role == "640ec4ec941ddd210c4dae9d") {
      history(`/taster-setting`);
    }
    if (role == "640ec508941ddd210c4daea7") {
      history(`/grocery-setting`);
    }
    if (role == "640ec4f7941ddd210c4daea2") {
      history(`/restaurent-setting`);
    }
  };
  const orderfn = (e) => {
    e.preventDefault();
    if (role == "640ec4c5941ddd210c4dae93") {
      history(`/chef-orders`);
    }
    if (role == "640ec4da941ddd210c4dae98") {
      history(`/driver-orders`);
    }
    if (role == "640ec4ec941ddd210c4dae9d") {
      history(`/taster-orders`);
    }
    if (role == "640ec508941ddd210c4daea7") {
      history(`/grocery-orders`);
    }
    if (role == "640ec4f7941ddd210c4daea2") {
      history(`/restaurent-orders`);
    }
  };

  return (
    <>
      {" "}
      <Signupmodel
        show={appear}
        setShow={setAppear}
        handleClose={handleClose}
      />
      <div className={styles.header}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-2 col-md-3 col-sm-3 col-4">
              <div className={styles.logo}>
                <a href="/">
                  <img src="/images/logo.png" alt="" />
                </a>
              </div>
            </div>{" "}
            <div className="col-xl-6 col-lg-8 col-sm-7 col-md-8">
              <div className={styles.headerSearch}>
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option defaultValue>Open</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <input type="text" />
                <button>
                  <img src="/images/search.svg" alt="" />
                </button>
              </div>
            </div>
            <div className="col-8 col-md-1 d-block d-xl-none col-sm-2">
              {" "}
              <div
                className={`${styles["nav"]} ${
                  isMenuOpen ? styles.active : null
                }`}
              >
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Sign Up
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(!show);
                  }}
                >
                  Login
                </a>
                <a
                  href="#"
                  className={styles.ask}
                  onClick={(e) => {
                    e.preventDefault();
                    setVisible(!visible);
                  }}
                >
                  Ask for Help?
                </a>
              </div>{" "}
              <div
                className={`${styles["menu-trigger"]} ${
                  isMenuOpen ? styles.active : null
                }`}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <div className={styles.line}></div>
                <div className={styles.line}></div>
                <div className={styles.line}></div>
              </div>
            </div>
            <div className="col-md-4 col-xl-3 col-lg-4 d-none d-xl-block text-end">
              <div className={styles.navLink}>
              
                {/* hide and show */}

                <div className="d-flex align-items-center">
                  <div>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-bell"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                      </svg>
                    </a>
                  </div>

                  <div>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-envelope"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                      </svg>
                    </a>
                  </div>

                  <div>
                    <OffcanvasModal placement="end" name="Carts" />
                  </div>

                  <div>
                    <a
                      href="#"
                      className={styles.ask}
                      onClick={(e) => {
                        e.preventDefault();
                        setVisible(!visible);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-basket"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z" />
                      </svg>
                    </a>
                  </div>

                  <div>
                  

                    <div>
                      <Dropdown>
                        <Dropdown.Toggle
                        style={{
                          border: "2px solid gray",
                          background: "transparent",
                          borderRadius: "100%",
                          marginTop: "5px",
                          width: "50px",
                          height: "50px",
                         
                          textAlign: "center",
                        }}
                          variant="success"
                          id="dropdown-basic"
                        >
                          <img
                           style={{
                            width: "25px",
                            height: "25px",
                            display:"flex",
                          }}
                         
                            src={
                              userdata?.upload_profile
                                ? userdata?.upload_profile
                                : "../images/headeruser.png"
                            }
                            alt=""
                          ></img>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e) => {
                              profilefn();
                            }}
                          >
                            <span className="d-flex align-items-center">
                              <span
                                style={{
                                  border: "2px solid gray",
                                  background: "transparent",
                                  borderRadius: "100%",
                                  marginTop: "5px",
                                  width: "60px",
                                  height: "60px",
                                  padding: "5px",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                <img
                                  style={{
                                
                                    width: "25px",
                                    height: "25px",
                                    margin: "6px",
                                  }}
                                  src={
                                    userdata?.upload_profile
                                      ? userdata?.upload_profile
                                      : "../images/headeruser.png"
                                  }
                                  alt=""
                                ></img>
                              </span>
                              <span className="ms-3">
                                <span>{userdata?.full_name}</span>
                                <h6>{userdata?.role?.name}</h6>
                              </span>
                            </span>
                          </Dropdown.Item>
                          <hr></hr>
                          <Dropdown.Item
                            onClick={(e) => {
                              profilefn(e);
                            }}
                          >
                            Profile
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              orderfn(e);
                            }}
                          >
                            My orders
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              settingfn(e);
                            }}
                          >
                            Settings
                          </Dropdown.Item>
                          <Dropdown.Item
                            href=""
                            onClick={(e) => {
                              Logoutfn(e);
                            }}
                          >
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InnerHeader;

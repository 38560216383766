import React, { useState, useEffect, useCallback } from "react";
import { PrevButton, NextButton } from "../slider-button2/index";
import useEmblaCarousel from "embla-carousel-react";
import MemberBox from "../member-box";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const CustomerSlider = ({ slides, data }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    dragFree: true,
    containScroll: "trimSnaps",
    loop: true,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  useEffect(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    onSelect();
  }, [embla, onSelect]);
  return (
    <div className="customerSliderMain">
      {/* <div ref={viewportRef} className="embla__viewport">
        <div className="embla__container">
          <div className="customerSliderInner">
            <MemberBox></MemberBox>
            <MemberBox></MemberBox>
            <MemberBox></MemberBox>
            <MemberBox></MemberBox>
            <MemberBox></MemberBox>
            <MemberBox></MemberBox>
            <MemberBox></MemberBox>
            <MemberBox></MemberBox>
            <MemberBox></MemberBox>
            <MemberBox></MemberBox>





           
          </div>
        </div>
      </div> */}
      <Carousel responsive={responsive}   infinite={true}>
  <div> <MemberBox></MemberBox> <MemberBox></MemberBox></div>
  <div> <MemberBox></MemberBox> <MemberBox></MemberBox></div>
  <div>  <MemberBox></MemberBox>  <MemberBox></MemberBox></div>
  <div> <MemberBox></MemberBox>  <MemberBox></MemberBox></div>
  <div> <MemberBox></MemberBox>  <MemberBox></MemberBox></div>
  <div> <MemberBox></MemberBox> <MemberBox></MemberBox></div>
  <div> <MemberBox></MemberBox> <MemberBox></MemberBox></div>
  <div>  <MemberBox></MemberBox>  <MemberBox></MemberBox></div>
  <div> <MemberBox></MemberBox>  <MemberBox></MemberBox></div>
  <div> <MemberBox></MemberBox>  <MemberBox></MemberBox></div>
</Carousel>;
      {/* <div>
        <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
      </div> */}
    </div>

    
  );
};
export default CustomerSlider;

import React from "react";

var UserOrderContext = React.createContext();
var UserOrderDispatchContext = React.createContext();
const initialState = {
  Orderdetailstatus: "",
};
function useReducer(state, action) {
  switch (action.type) {
    case "Completed Orders":
      return { ...state, Orderdetailstatus: 3 };
    case "Orders awaiting delivery":
      return { ...state, Orderdetailstatus: 2 };
    case "Orders Awaiting Confirmation":
      return { ...state, Orderdetailstatus: 0 };
    case "Pending Feedback ":
      return { ...state, Orderdetailstatus: "" };
    case "Disputed Orders":
      return { ...state, Orderdetailstatus: "" };
    case "Feedbacks Given ":
      return { ...state, Orderdetailstatus: "" };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserOrderProvider({ children }) {
  var [state, dispatch] = React.useReducer(useReducer, initialState);
  return (
    <UserOrderContext.Provider value={state}>
      <UserOrderDispatchContext.Provider value={dispatch}>
        {children}
      </UserOrderDispatchContext.Provider>
    </UserOrderContext.Provider>
  );
}

function useUserOrderState() {
  var context = React.useContext(UserOrderContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserOrderDispatch() {
  var context = React.useContext(UserOrderDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserOrderProvider, useUserOrderState, useUserOrderDispatch };

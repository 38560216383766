import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";

import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { ForgetPassword } from "../../services/auth.service";

const Login = ({
  show,
  setShow,
  props,
  handleSubmit,
  onChangeInput,
  ErrorObject,
}) => {
  const handleSubmitt = (e) => {
    e.preventDefault();
    let body = {
      email: email,
    };
    ForgetPassword(body).then((response) => {
      if (response?.success) {
        setforgetmodal(false);
        toast.success("email has been send");
      }
    });
  };
  // const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [forgetmodal, setforgetmodal] = useState(false);
  const handleClose = () => setShow(false);
  const handlecloses = () => setforgetmodal(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="customModal"
        {...props}
        backdrop={"static"}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            {" "}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email or Username</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                name="email"
                onChange={(e) => {
                  onChangeInput(e);
                }}
              />
              <span className="fs-6 text-danger">{ErrorObject?.email}</span>
            </Form.Group>{" "}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                onChange={(e) => {
                  onChangeInput(e);
                }}
              />
              <span className="fs-6 text-danger">{ErrorObject?.password}</span>
            </Form.Group>
            <div className="forgetLink d-flex justify-content-between">
              {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setforgetmodal(true);
                  setShow(false);
                }}
              >
                Forget Password
              </a>
              <a href="#">Create an account</a>
            </div>
            <div className="mT20 mB20">
              <button
                className="bdrBtn"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Login
              </button>
            </div>
            <div className="logInWith">
              <h5>
                <span>OR</span>
              </h5>
              <span className="">
                <a href="">
                  <i className="fa-brands fa-facebook"></i>
                </a>
                <a href="">
                  <i className="fa-brands fa-google-plus"></i>
                </a>
              </span>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={forgetmodal} onHide={handlecloses} className="custommModal">
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Forget Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Enter your email address and we will send you a link to reset it.
          <input
            placeholder="name@example.com"
            name="email"
            type="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            id="exampleForm.ControlInput1"
            className="form-control mt-2"
          ></input>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={(e) => {
              handleSubmitt(e);
            }}
          >
            Reset my password
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Login;

import Header from "../../src/component/Header/index";
import Footer from "../../src/component/Footer/index";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ResetPassword, CheckPassword } from "../services/auth.service";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";

const Resetpasswords = () => {
  const history = useNavigate();
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [errormessage, seterrormeassage] = useState("");
  const [passworderrormessage, setpassworderrormeassage] = useState("");
  const [resetstatus, setresetstatus] = useState(false);
  // const search = useLocation().search;
  // const searchParams = new URLSearchParams(search);
  // const token = searchParams.get("token");
  // const email = searchParams.get("email");

  const queryParameters = new URLSearchParams(window.location.search);

  const token = queryParameters.get("token");
  const email = queryParameters.get("email");

  useMemo(() => {
    CheckPassword({
      email: email,
      token: token,
    }).then((response) => {
      if (!response?.success) {
        history(`/`);
      }
    });
  }, [resetstatus]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.length < 8) {
      setpassworderrormeassage("password must be 8 character");
      return;
    }
    if (confirmpassword != password) {
      seterrormeassage("confirm password not match");
      return;
    }

    const body = {
      email: email,
      token: token,
      password: password,
    };
    ResetPassword(body).then((response) => {
      if (response?.success) {
        toast.success("password has been changed", { position: "top-right" });
        setresetstatus(true);
      }
    });
  };

  return (
    <>
      <Header />
      <Container className="mt-5">
        <Row>
          <div>
            <h2 className="text-center fs-1 fw-bold">Reset Your Password</h2>
            <p className="text-center text-danger">
              Your password needs to be at least 8 characters.
            </p>
          </div>

          <Form className="mb-5">
            <Form.Group className="mb-2" controlId="formBasicPassword">
              <Form.Label>New Password*</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => {
                  setpassworderrormeassage("");
                  setpassword(e.target.value);
                }}
              />
              <span className="text-danger">{passworderrormessage}</span>
            </Form.Group>

            <Form.Group className="mb-2 mt-3" controlId="formBasicPassword">
              <Form.Label>Confirm Password*</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => {
                  seterrormeassage("");
                  setconfirmpassword(e.target.value);
                }}
              />
              <span className="text-danger">{errormessage}</span>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Reset password
            </Button>
          </Form>
        </Row>
      </Container>
      <Footer />
    </>
  );
};
export default Resetpasswords;

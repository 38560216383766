import React from "react";

export const PrevButton = ({ enabled, onClick }) => (
  <button
    className="embla__button embla__button--prev sliderArrows sliderArrows1 leftArrow"
    onClick={onClick}
    disabled={!enabled}
  >
    <svg
      width="40"
      height="35"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.07227C0 1.02669 0.00911458 0.985677 0.0273438 0.949219C0.0455729 0.903646 0.0683594 0.86263 0.0957031 0.826172C0.16862 0.76237 0.250651 0.730469 0.341797 0.730469C0.442057 0.730469 0.528646 0.76237 0.601562 0.826172L6.64453 6.88281L12.7012 0.826172C12.7741 0.76237 12.8561 0.730469 12.9473 0.730469C13.0475 0.730469 13.1296 0.76237 13.1934 0.826172C13.2663 0.899089 13.3027 0.985677 13.3027 1.08594C13.3027 1.17708 13.2663 1.25456 13.1934 1.31836L6.89062 7.62109C6.82682 7.69401 6.74479 7.73047 6.64453 7.73047C6.55339 7.73047 6.47135 7.69401 6.39844 7.62109L0.0957031 1.31836C0.0683594 1.29102 0.0455729 1.25456 0.0273438 1.20898C0.00911458 1.16341 0 1.11784 0 1.07227Z"
        fill="black"
      />
    </svg>
  </button>
);

export const NextButton = ({ enabled, onClick }) => (
  <button
    className="embla__button embla__button--next sliderArrows1 sliderArrows rightArrow"
    onClick={onClick}
    disabled={!enabled}
  >
    <svg
      width="40"
      height="35"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.07227C0 1.02669 0.00911458 0.985677 0.0273438 0.949219C0.0455729 0.903646 0.0683594 0.86263 0.0957031 0.826172C0.16862 0.76237 0.250651 0.730469 0.341797 0.730469C0.442057 0.730469 0.528646 0.76237 0.601562 0.826172L6.64453 6.88281L12.7012 0.826172C12.7741 0.76237 12.8561 0.730469 12.9473 0.730469C13.0475 0.730469 13.1296 0.76237 13.1934 0.826172C13.2663 0.899089 13.3027 0.985677 13.3027 1.08594C13.3027 1.17708 13.2663 1.25456 13.1934 1.31836L6.89062 7.62109C6.82682 7.69401 6.74479 7.73047 6.64453 7.73047C6.55339 7.73047 6.47135 7.69401 6.39844 7.62109L0.0957031 1.31836C0.0683594 1.29102 0.0455729 1.25456 0.0273438 1.20898C0.00911458 1.16341 0 1.11784 0 1.07227Z"
        fill="black"
      />
    </svg>
  </button>
);

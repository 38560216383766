import React, { useState, useEffect, useCallback, useMemo } from "react";
import { PrevButton, NextButton } from "../slider-button/index";
import useEmblaCarousel from "embla-carousel-react";

const Banner = ({ slides, data }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    dragFree: true,
    containScroll: "trimSnaps",
    loop: true,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useMemo(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    onSelect();
  }, [embla, onSelect]);
  return (
    <div className="bannerMain">
      <div ref={viewportRef} className="embla__viewport">
        <div className="embla__container" style={{ marginLeft: "-10px" }}>
          <div className="bannerInner">
            <img src="images/banner1.jpg" alt="" />
            <div className="bannerText">
              <h1>
                Discover Local Food Professionals near you anywhere in the World
              </h1>
              <p>
                We help all food professionals create an online presence and
                portfolio for their food business.Discover Local Food
                Professionals near you anywhere in the World
              </p>
            </div>
          </div>
          <div className="bannerInner">
            <img src="images/banner2.png" alt="" />
            <div className="bannerText">
              <h1>
                Discover Local Food Professionals near you anywhere in the World
              </h1>
              <p>
                We help all food professionals create an online presence and
                portfolio for their food business.Discover Local Food
                Professionals near you anywhere in the World
              </p>
            </div>
          </div>
          <div className="bannerInner">
            <img src="images/banner1.jpg" alt="" />
            <div className="bannerText">
              <h1>
                Discover Local Food Professionals near you anywhere in the World
              </h1>
              <p>
                We help all food professionals create an online presence and
                portfolio for their food business.Discover Local Food
                Professionals near you anywhere in the World
              </p>
            </div>
          </div>
          <div className="bannerInner">
            <img src="images/banner2.png" alt="" />
            <div className="bannerText">
              <h1>
                Discover Local Food Professionals near you anywhere in the World
              </h1>
              <p>
                We help all food professionals create an online presence and
                portfolio for their food business.Discover Local Food
                Professionals near you anywhere in the World
              </p>
            </div>
          </div>
          <div className="bannerInner">
            <img src="images/banner1.jpg" alt="" />
            <div className="bannerText">
              <h1>
                Discover Local Food Professionals near you anywhere in the World
              </h1>
              <p>
                We help all food professionals create an online presence and
                portfolio for their food business.Discover Local Food
                Professionals near you anywhere in the World
              </p>
            </div>
          </div>
          <div className="bannerInner">
            <img src="images/banner2.png" alt="" />
            <div className="bannerText">
              <h1>
                Discover Local Food Professionals near you anywhere in the World
              </h1>
              <p>
                We help all food professionals create an online presence and
                portfolio for their food business.Discover Local Food
                Professionals near you anywhere in the World
              </p>
            </div>
          </div>
          <div className="bannerInner">
            <img src="images/banner1.jpg" alt="" />
            <div className="bannerText">
              <h1>
                Discover Local Food Professionals near you anywhere in the World
              </h1>
              <p>
                We help all food professionals create an online presence and
                portfolio for their food business.Discover Local Food
                Professionals near you anywhere in the World
              </p>
            </div>
          </div>
          <div className="bannerInner">
            <img src="images/banner2.png" alt="" />
            <div className="bannerText">
              <h1>
                Discover Local Food Professionals near you anywhere in the World
              </h1>
              <p>
                We help all food professionals create an online presence and
                portfolio for their food business.Discover Local Food
                Professionals near you anywhere in the World
              </p>
            </div>
          </div>
          <div className="bannerInner">
            <img src="images/banner1.jpg" alt="" />
            <div className="bannerText">
              <h1>
                Discover Local Food Professionals near you anywhere in the World
              </h1>
              <p>
                We help all food professionals create an online presence and
                portfolio for their food business.Discover Local Food
                Professionals near you anywhere in the World
              </p>
            </div>
          </div>
          <div className="bannerInner">
            <img src="images/banner2.png" alt="" />
            <div className="bannerText">
              <h1>
                Discover Local Food Professionals near you anywhere in the World
              </h1>
              <p>
                We help all food professionals create an online presence and
                portfolio for their food business.Discover Local Food
                Professionals near you anywhere in the World
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <PrevButton
          className="fs-6"
          onClick={scrollPrev}
          enabled={prevBtnEnabled}
        />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
      </div>
    </div>
  );
};
export default Banner;

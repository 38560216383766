import apipath from "../../api/api_path";
import makeTheApiCall from "../../api/axios.config";

export async function createrequestsample(body) {
  let options = {
    url: apipath.BASE_URL + apipath.Addrequestsample,
    method: "POST",
    data: body,
  };
  const res = await makeTheApiCall(options);

  return res.data;
}

export async function addcartrequest(body) {
  let options = {
    url: apipath.BASE_URL + apipath.AddCartDetail,
    method: "POST",
    data: body,
  };
  const res = await makeTheApiCall(options);

  return res.data;
}

export async function getDishdetail(id) {
  let options = {
    url: apipath.BASE_URL + apipath.DISHDETAIL + "/" + id,
    method: "GET",
  };
  const res = await makeTheApiCall(options);

  return res.data;
}

export async function getcartDetails(id) {
  let options = {
    url: apipath.BASE_URL + apipath.CARTDETAIL + "/" + id,
    method: "GET",
  };
  const res = await makeTheApiCall(options);

  return res.data;
}
export async function getCartlist(body) {
  let options = {
    url: apipath.BASE_URL + apipath.cartlist,
    method: "POST",
    data: body,
  };
  const res = await makeTheApiCall(options);

  return res.data;
}
export async function getuserlikelist(body) {
  let options = {
    url: apipath.BASE_URL + apipath.UserLikeList,
    method: "POST",
    data: body,
  };
  const res = await makeTheApiCall(options);

  return res.data;
}

export async function getFeaturedmanlist() {
  let options = {
    url: apipath.BASE_URL + apipath.Featuredmanlist,
    method: "POST",
  };
  const res = await makeTheApiCall(options);

  return res.data;
}
export async function getFeaturedwomanlist(body) {
  let options = {
    url: apipath.BASE_URL + apipath.Featuredwomanlist,
    method: "POST",
    data: body,
  };
  const res = await makeTheApiCall(options);

  return res.data;
}

export async function geteventOrderist() {
  let options = {
    url: apipath.BASE_URL + apipath.EventOrderlist,
    method: "POST",
  };
  const res = await makeTheApiCall(options);

  return res.data;
}
export async function getserviceslist(body) {
  let options = {
    url: apipath.BASE_URL + apipath.Extraserviceslist,
    method: "POST",
    data: body,
  };
  const res = await makeTheApiCall(options);

  return res.data;
}

export async function getRecntallyaddedlist() {
  let options = {
    url: apipath.BASE_URL + apipath.Recentallyaddedlist,
    method: "POST",
  };
  const res = await makeTheApiCall(options);

  return res.data;
}

export async function getUpdateCart(body) {
  let options = {
    url: apipath.BASE_URL + apipath.UpdateCart,
    method: "PUT",
    data: body,
  };
  const res = await makeTheApiCall(options);

  return res.data;
}
export async function Updatelivefeed(body, id) {
  let options = {
    url: apipath.BASE_URL + apipath.UpdateLiveFeed + "/" + id,
    method: "PUT",
    data: body,
  };
  const res = await makeTheApiCall(options);

  return res.data;
}
export async function getOrderdetail(id) {
  let options = {
    url: apipath.BASE_URL + apipath.Orderdetail + "/" + id,
    method: "GET",
  };
  const res = await makeTheApiCall(options);

  return res.data;
}

export async function getdeletecart(body) {
  let options = {
    url: apipath.BASE_URL + apipath.deleteCart,
    method: "DELETE",
    data: body,
  };
  const res = await makeTheApiCall(options);

  return res.data;
}

export async function createcheckoutform(body) {
  let options = {
    url: apipath.BASE_URL + apipath.checkoutform,
    method: "POST",
    data: body,
  };
  const res = await makeTheApiCall(options);

  return res.data;
}
export async function CreateOrder(body) {
  let options = {
    url: apipath.BASE_URL + apipath.createOrder,
    method: "POST",
    data: body,
  };
  const res = await makeTheApiCall(options);

  return res.data;
}
export async function createEventorder(body) {
  let options = {
    url: apipath.BASE_URL + apipath.createeventOrder,
    method: "POST",
    data: body,
  };
  const res = await makeTheApiCall(options);

  return res.data;
}
export async function getdetaileventproject(id) {
  let options = {
    url: apipath.BASE_URL + apipath.EventorderDETAIL + "/" + id,
    method: "GET",
  };
  const res = await makeTheApiCall(options);

  return res.data;
}
export async function UpdatechefInvite(body, id) {
  let options = {
    url: apipath.BASE_URL + apipath.Updatechefinvite + "/" + id,
    method: "PUT",
    data: body,
  };
  const res = await makeTheApiCall(options);

  return res.data;
}

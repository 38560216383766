import React, { useState, useEffect } from "react";

import "./invitecart.css";
import { useChefInvite } from "../../context-invitechef/invite-chef";

export default function Invitecart() {
  const { chefdata } = useChefInvite();

  if (chefdata) {
    var length = chefdata.length;
  }

  return (
    <>
      <div class="container">
        <div class="row">
          <div class="modal-content1">
            <div class="modal-header1">
              <h5 class="modal-title1 text-center">
                Invite <text class="ivt-chef"></text>{" "}
              </h5>
              <a href="/create-project">
                {" "}
                <button type="button" class="close" data-dismiss="modal">
                  ×
                </button>
              </a>
            </div>

            <div class="modal-body1">
              <form
                action=""
                role="form"
                class="form-horizontal"
                id="frm_invite_chef"
                name="frm_invite_chef"
                enctype="multipart/form-data"
                novalidate="novalidate"
              >
                <div>
                  <label for="evt_title">Event Title:</label>
                  teryru{" "}
                </div>
                <div>
                  <label for="evt_title">Start Date:</label>
                  19 Jul 2023{" "}
                </div>
                <div>
                  <label for="evt_title">End Date:</label>
                  22 Jul 2023{" "}
                </div>
                <div>
                  <label for="message">Message:</label>
                  <textarea
                    style={{ height: "100%" }}
                    class="form-control"
                    rows="5"
                    cols="10"
                    id="txt_message"
                    name="fields[invite_message]"
                    placeholder="Your invite message"
                    value=""
                    required=""
                    aria-required="true"
                  ></textarea>
                </div>
                <input
                  type="hidden"
                  id="multi_invite_chef"
                  name="multi_invite"
                  value=""
                />
                <div class="modal-footer1">
                  <button
                    name="reset"
                    id="invite-splorder"
                    data-dish-id=""
                    data-dismiss="modal"
                    class="btn btn-default btn-msg-modal"
                    title="Cancel"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    name="submit"
                    id="chef_splorder"
                    data-otherid="215"
                    class="request-invite btn btn-primary btn-msg-modal"
                    title="Save"
                  >
                    Save
                  </button>
                </div>

                <input
                  type="hidden"
                  class="form-control"
                  name="event-id"
                  id="event-id"
                  value="158"
                />
                <input
                  type="hidden"
                  class="invite_other"
                  name="fields[invite_user_id]"
                  value=""
                />
                <input
                  type="hidden"
                  class="event_title"
                  name="event_title"
                  value="teryru"
                />
                <input
                  type="hidden"
                  class="taster_name"
                  name="taster_name"
                  value="sharma"
                />
              </form>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

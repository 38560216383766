import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./context/auth.context";
import { UserOrderProvider } from "./context-order/order.context";
import { Context } from "./context-cart/context";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { InviteChefProvider } from "./context-invitechef/invite-chef";
import { ToastContainer } from "react-toastify";
import { ContextProvider } from "./context-loader/loader.context";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/globals.scss";
import "./styles/partials/all.css";
import "./styles/partials/flags.min.css";
// import "./styles/partials/font-awesome.min.css"
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <GoogleOAuthProvider clientId="1006943610162-1o78nf3t1vkovdi6fdn20pi606skkc92.apps.googleusercontent.com">
    <UserProvider>
      <Context>
        <ContextProvider>
          <UserOrderProvider>
            <InviteChefProvider>
              <React.StrictMode>
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                />
                <App />
              </React.StrictMode>
            </InviteChefProvider>
          </UserOrderProvider>
        </ContextProvider>
      </Context>
    </UserProvider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

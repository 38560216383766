import React from "react";

const Groceryprofile = React.lazy(() =>
  import("./pages/Grocery/grocery_profile")
);
const Driverdashboard = React.lazy(() => import("./pages/driver/dashboard"));
const GroceryOrder = React.lazy(() =>
  import("./pages/Grocery/sidebar/grocery-orders")
);
const GroceryFeaturedman = React.lazy(() =>
  import("./pages/Grocery/sidebar/grocery-featured-man")
);
const GroceryFeaturedwoman = React.lazy(() =>
  import("./pages/Grocery/sidebar/grocery-featured-women")
);
const GrocerySetting = React.lazy(() =>
  import("./pages/Grocery/sidebar/grocery-setting")
);
const GroceryRecentallyadded = React.lazy(() =>
  import("./pages/Grocery/sidebar/grocery-recentally-added")
);
const Groceryservices = React.lazy(() =>
  import("./pages/Grocery/sidebar/services")
);
const GroceryAdddish = React.lazy(() =>
  import("./pages/Grocery/sidebar/grocery-adddish")
);
const Grocery = React.lazy(() => import("./pages/Grocery/GroceryHome"));
const Edit_profiles = React.lazy(() => import("./pages/chef/edit-profile"));
const GroceryMyprofile = React.lazy(() =>
  import("./pages/Grocery/sidebar/grocery-profile")
);
const Profile = React.lazy(() => import("./pages/Grocery/grocery_profile"));

const groceryroutes = [
  {
    path: "/edit-profile",
    exact: true,
    name: "Homepage",
    element: Edit_profiles,
  },

  {
    path: "/grocery-myprofile",
    exact: true,
    name: "Grocerymyprofile",
    element: GroceryMyprofile,
  },

  { path: "/grocery-home", exact: true, name: "Homepage", element: Grocery },

  { path: "/grocery-profile", exact: true, name: "Homepage", element: Profile },

  {
    path: "/grocery-dashboard",
    exact: true,
    name: "Homepage",
    element: Profile,
  },

  {
    path: "/grocery-profile",
    exact: true,
    name: "Groceryprofile",
    element: Groceryprofile,
  },

  {
    path: "/grocery-recently-added",
    exact: true,
    name: "GroceryRecentallyadded",
    element: GroceryRecentallyadded,
  },
  {
    path: "/grocery-orders",
    exact: true,
    name: "GroceryOrder",
    element: GroceryOrder,
  },
  {
    path: "/grocery-featured-man",
    exact: true,
    name: "GroceryFeaturedman",
    element: GroceryFeaturedman,
  },
  {
    path: "/grocery-services",
    exact: true,
    name: "Groceryservices",
    element: Groceryservices,
  },
  {
    path: "/grocery-setting",
    exact: true,
    name: "GrocerySetting",
    element: GrocerySetting,
  },
  {
    path: "/grocery-featured-woman",
    exact: true,
    name: "GroceryFeaturedwoman",
    element: GroceryFeaturedwoman,
  },

  {
    path: "/grocery-addish",
    exact: true,
    name: "GroceryAdddish",
    element: GroceryAdddish,
  },
];

export default groceryroutes;

import React from "react";
import Invitecard from "./pages/taster-dashboard/invitecart";

// import Dishcard from "./pages/taster-dashboard/dishcard";

const Tasterdashboard = React.lazy(() =>
  import("./pages/taster-dashboard/index")
);
// const Tasterorders = React.lazy(() =>
//   import("./pages/taster-dashboard/sidebar/orders")
// );
const Tasterrecentalyadded = React.lazy(() =>
  import("./pages/taster-dashboard/sidebar/recentely-added")
);
const Tasterfeaturedman = React.lazy(() =>
  import("./pages/taster-dashboard/sidebar/featuredman")
);
const Tasterfeaturedwoman = React.lazy(() =>
  import("./pages/taster-dashboard/sidebar/featuredwoman")
);
const Tastergrocery = React.lazy(() =>
  import("./pages/taster-dashboard/sidebar/grocery")
);
const Tastergroceryorder = React.lazy(() =>
  import("./pages/taster-dashboard/sidebar/groceryorders")
);
const Tasteractivelog = React.lazy(() =>
  import("./pages/taster-dashboard/sidebar/activelog")
);
const Tasteranewfeeds = React.lazy(() =>
  import("./pages/taster-dashboard/sidebar/newsfeed")
);
const Tasteroffers = React.lazy(() =>
  import("./pages/taster-dashboard/sidebar/offers")
);
const Tasterprojects = React.lazy(() =>
  import("./pages/taster-dashboard/sidebar/project")
);

const Edit_profiles = React.lazy(() => import("./pages/chef/edit-profile"));
const Tastersetting = React.lazy(() =>
  import("./pages/taster-dashboard/sidebar/setting")
);
const Tasterorders = React.lazy(() =>
  import("./pages/taster-dashboard/sidebar/orders")
);
const Edittaster = React.lazy(() => import("./pages/edittaster/edittaster"));

const Tasterprofile = React.lazy(() =>
  import("./pages/taster-dashboard/sidebar/profile")
);
const Tasterextraservices = React.lazy(() =>
  import("./pages/taster-dashboard/sidebar/services")
);
const Orderviewdetails = React.lazy(() =>
  import("./pages/driver/sidebar/viewdetails")
);
const Tasterorderdetail = React.lazy(() =>
  import("./pages/taster-dashboard/taster-orderdetails")
);

const EditGrocery = React.lazy(() => import("./pages/edittaster/edit-grocery"));
const DishItems = React.lazy(() =>
  import("./pages/taster-dashboard/DashBoard_items/items")
);
const Checkout = React.lazy(() =>
  import("./pages/taster-dashboard/checkoutpage")
);
const CheckoutPayment = React.lazy(() => import("./pages/payment/index"));
const PaymentSuccess = React.lazy(() =>
  import("./pages/payment/paymentsuccess")
);
const Dishcart = React.lazy(() => import("./pages/taster-dashboard/dishcart"));

const Createproject = React.lazy(() =>
  import("./pages/taster-dashboard/Createproject")
);

const Invitecart = React.lazy(() =>
  import("./pages/taster-dashboard/invitecart")
);



const Hiredriver = React.lazy(() =>
  import("./component/edittaster/Hiredriver")
);

const tasterroutes = [
  {
    path: "/edit-grocery",
    exact: true,
    name: "EditGrocery",
    element: EditGrocery,
  },

  {
    path: "/create-project/:id",
    exact: true,
    name: "Createproject",
    element: Createproject,
  },

  {
    path: "/cart/order-detail",
    exact: true,
    name: "Dishcart",
    element: Dishcart,
  },

  {
    path: "project/invite-cart",
    exact: true,
    name: "Invitecart",
    element: Invitecart,
  },

  {
    path: "/order/payment",
    exact: true,
    name: "CheckoutPayment",
    element: CheckoutPayment,
  },
  {
    path: "/payment-success",
    exact: true,
    name: "PaymentSuccess",
    element: PaymentSuccess,
  },
  {
    path: "/dish/check-out/:id",
    exact: true,
    name: "Checkout",
    element: Checkout,
  },

  {
    path: "/dashboard_items/:id",
    exact: true,
    name: "DishItems",
    element: DishItems,
  },
  {
    path: "/taster-orderdetail",
    exact: true,
    name: "Tasterorderdetail",
    element: Tasterorderdetail,
  },
  {
    path: "/edittaster",
    exact: true,
    name: "Edittaster",
    element: Edittaster,
  },
  {
    path: "/taster-profile",
    exact: true,
    name: "Tasterprofile",
    element: Tasterprofile,
  },
  {
    path: "/taster-orders",
    exact: true,
    name: "Tasterorders",
    element: Tasterorders,
  },
  {
    path: "/taster-dashboard",
    exact: true,
    name: "Tasterdashboard",
    element: Tasterdashboard,
  },
  {
    path: "/taster-setting",
    exact: true,
    name: "Tastersetting",
    element: Tastersetting,
  },
  {
    path: "/taster-project",
    exact: true,
    name: "Tasterprojects",
    element: Tasterprojects,
  },
  {
    path: "/taster-offers",
    exact: true,
    name: "Tasteroffers",
    element: Tasteroffers,
  },
  {
    path: "/taster-newfeeds",
    exact: true,
    name: "Tasteranewfeeds",
    element: Tasteranewfeeds,
  },
  {
    path: "/taster-activelogs",
    exact: true,
    name: "Tasteractivelog",
    element: Tasteractivelog,
  },
  {
    path: "/taster-groceryorders",
    exact: true,
    name: "Tastergroceryorder",
    element: Tastergroceryorder,
  },
  {
    path: "/taster-grocery",
    exact: true,
    name: "Tastergrocery",
    element: Tastergrocery,
  },
  {
    path: "/taster-featured-man",
    exact: true,
    name: "Tasterfeaturedman",
    element: Tasterfeaturedman,
  },
  {
    path: "/taster-featured-woman",
    exact: true,
    name: "Tasterfeaturedwoman",
    element: Tasterfeaturedwoman,
  },
  {
    path: "/taster-recentely-added",
    exact: true,
    name: "Tasterrecentalyadded",
    element: Tasterrecentalyadded,
  },
  {
    path: "/order-view-details",
    exact: true,
    name: "Orderviewdetails",
    element: Orderviewdetails,
  },

  {
    path: "/taster-extra-services",
    exact: true,
    name: "Tasterextraservices",
    element: Tasterextraservices,
  },



  {
    path: "hire-driver",
    exact: true,
    name: "hire-driver",
    element: Hiredriver,
  },




];

export default tasterroutes;

import { useState, useEffect } from "react";
import styles from "../../styles/modules/trusted.module.scss";
import Col from "react-bootstrap/Col";

const TrustedSection = ({ variant }) => {
  return (
    <div className={styles.trustedMain}>
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-center mb-5">
            <h1 className="headingsssss">
              Build A Pool Of <span>Trusted Experts</span> For Your Team
            </h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <Col md={2}>
            <div className={styles.trustBox}>
              <div className={styles.trustBox1}>
                <div className={styles.img}>
                  <img src="images/logo-cook.png" alt="" />
                </div>
                <h3>Chefs & Cooks Chefs & Cooks</h3>
              </div>
              <div className={styles.trustBox2}>
                <h3>Chefs & Cooks Chefs & Cooks</h3>
                <a href="#">View Benefit</a>
              </div>
            </div>
          </Col>
          <Col md={2}>
            {" "}
            <div className={styles.trustBox}>
              <div className={styles.trustBox1}>
                <div className={styles.img}>
                  <img src="images/logo_taster.png" alt="" />
                </div>
                <h3>Customers & Foodies</h3>
              </div>
              <div className={styles.trustBox2}>
                {" "}
                <h3>Customers & Foodies</h3>
                <a href="#">View Benefit</a>
              </div>
            </div>
          </Col>
          <Col md={2}>
            {" "}
            <div className={styles.trustBox}>
              <div className={styles.trustBox1}>
                <div className={styles.img}>
                  <img src="images/logo_grocery.png" alt="" />
                </div>
                <h3>Groceries & Convenience Stores</h3>
              </div>
              <div className={styles.trustBox2}>
                <h3>Groceries & Convenience Stores</h3>
                <a href="#">View Benefit</a>
              </div>
            </div>
          </Col>
          <Col md={2}>
            <div className={styles.trustBox}>
              <div className={styles.trustBox1}>
                <div className={styles.img}>
                  <img src="images/logo_delivery.png" alt="" />
                </div>
                <h3>Food Trucks & Carts</h3>
              </div>
              <div className={styles.trustBox2}>
                <h3>Food Trucks & Carts</h3>
                <a href="#">View Benefit</a>
              </div>
            </div>
          </Col>
          <Col md={2}>
            {" "}
            <div className={styles.trustBox}>
              <div className={styles.trustBox1}>
                <div className={styles.img}>
                  <img src="images/logo_restaurants.png" alt="" />
                </div>
                <h3>Restaurants & Catering Companies</h3>
              </div>
              <div className={styles.trustBox2}>
                {" "}
                <h3>Restaurants & Catering Companies</h3>
                <a href="#">View Benefit</a>
              </div>
            </div>
          </Col>
          <Col md={2}>
            {" "}
            <div className={styles.trustBox}>
              <div className={styles.trustBox1}>
                <div className={styles.img}>
                  <img src="images/logo_restaurants.png" alt="" />
                </div>
                <h3>Restaurants & Catering Companies</h3>
              </div>
              <div className={styles.trustBox2}>
                {" "}
                <h3>Restaurants & Catering Companies</h3>
                <a href="#">View Benefit</a>
              </div>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
};
export default TrustedSection;

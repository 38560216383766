import React from "react";
import styles from "../../styles/modules/footer.module.scss";
const Footer = () => {
  return (
    <div>
      {" "}
      <div className="footer">
        <div className="container">
          <div className="footer-block">
            <div className="f-about">
              <p className="default-p">
                We aim to home produce as much as possible for the best Quality
                and to reduce food delicious cakes traditionally Devon Soups,
                Sources &amp; Accompanimentization.{" "}
              </p>
            </div>
            <div className="f-qucik-links">
              <ul className="f-ul">
                <li>
                  <a href="#">CONTACT US</a>
                </li>
                <li>
                  <a href="#">TERMS OF USE</a>
                </li>
                <li>
                  <a href="#">FAQs</a>
                </li>
              </ul>
            </div>
            <div className="f-social">
              <div className="social_sec" id="social_icon_display">
                <a
                  href="https://www.facebook.com"
                  id="fb_link"
                  title=""
                  target="_blank"
                >
                  {" "}
                  <i className="fa-brands fa-facebook-f"></i>{" "}
                </a>

                <a
                  href="https://www.youtube.com/"
                  id="yt_link"
                  title=""
                  target="_blank"
                >
                  <i className="fa-brands fa-youtube"></i>{" "}
                </a>

                <a
                  href="https://twitter.com/"
                  id="tw_link"
                  title=""
                  target="_blank"
                >
                  <i className="fa-brands fa-twitter"></i>{" "}
                </a>

                <a
                  href="https://www.instagram.com/"
                  id="ig_link"
                  title=""
                  target="_blank"
                >
                  <i className="fa-brands fa-instagram"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>@ Copyright PayMeToCook Inc. 2018. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;

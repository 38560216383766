import React from "react";
import { Container, Button, Form, Col, Row } from "react-bootstrap";
import styles from "../../styles/modules/signup.module.scss";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Signuprestro } from "../../services/auth.service";
import { toast } from "react-toastify";
import Googlemap from "../../component/googlemap/map";
import Select from "react-select";
import { Validator } from "../../utiles/signup/signuprestro.validator";

import { Country, State, City } from "country-state-city";

const SignupForm = () => {
  const history = useNavigate();
  const [role, setRole] = useState("");
  const [state, setstate] = useState("");
  const { id } = useParams();

  const [Signupdetail, setSignupdetails] = useState({});
  const [ErrorObject, setErrorObject] = useState({});
  const location = {
    // address: "1600 Amphitheatre Parkway, Mountain View, california.",
    address: "malviya nagar jaipur",
    lat: 37.42216,
    lng: -122.08427,
  };
  // const options = useMemo(() => countryList().getData(), []);
  const option = useMemo(
    () =>
      State.getAllStates().map((items) => {
        return {
          value: items.name,
          label: items.name,
        };
      }),

    []
  );
  const options = useMemo(
    () =>
      Country.getAllCountries().map((items) => {
        return {
          value: items.name,
          label: items.name,
        };
      }),

    []
  );

  useMemo(() => {
    if (id == "640ec4c5941ddd210c4dae93") {
      setRole("640ec4c5941ddd210c4dae93");
    } else if (id == "640ec4da941ddd210c4dae98") {
      setRole("640ec4da941ddd210c4dae98");
    } else if (id == "640ec4ec941ddd210c4dae9d") {
      setRole("640ec4ec941ddd210c4dae9d");
    } else if (id == "640ec508941ddd210c4daea7") {
      setRole("640ec508941ddd210c4daea7");
    } else {
      setRole("");
    }
  }, []);
  const onChangeInput = (e) => {
    e.preventDefault();
    setErrorObject({});
    setSignupdetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let result = Validator(Signupdetail);
    const { error } = result;

    if (error) {
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        const message = item.message;
        errorData[name] = message;
      }
      setErrorObject(errorData);
      window.scrollTo(100, 100);
      return;
    }

    let body = {
      // role: role,
      company_name: Signupdetail?.company_name,
      // address: Signupdetail?.address,
      contact_number: Signupdetail?.contact_number,
      country: Signupdetail?.country,
      // full_name: Signupdetail?.full_name,
      email: Signupdetail?.email,
      state: Signupdetail?.state,
      city: Signupdetail?.city,
      password: Signupdetail?.password,
      confirm_password: Signupdetail?.confirm_password,
      qualification: Signupdetail?.qualification,
      // gender: Signupdetail?.gender,
      // username: Signupdetail?.username,
      dob: Signupdetail?.dob,
      zipcode: Signupdetail?.zipcode,
    };
    Signuprestro(body).then((response) => {
      if (response?.success) {
        toast.success("signup successfully");
        history(`/`);
      } else {
        toast.error(response?.message);
      }
    });
  };

  return (
    <>
      <Container className="App">
        <div className={styles.headbody}>
          <h1>Tasters & Foodies</h1>
          <br />
          <div>
            <Button
              className={styles.signup}
              size="lg"
              style={{ marginRight: "10px" }}
            >
              Sign Up With Google
            </Button>{" "}
            <Button className={styles.signupfacebook} size="lg">
              Sign Up With Facebook
            </Button>
          </div>
        </div>

        <div className={styles.formBody}>
          <h1>PERSONAL DETAILS</h1>
          <p>"Tell us more about yourself"</p>
          <Form>
            <Col className="App">
              <Row>
                <Col xs={6}>
                  {/* <Form.Control
                    type="text"
                    name="username"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="User Name"
                    className={styles.inputBox}
                  /> */}
                </Col>
                <Col xs={6}>
                  {/* <Form.Control
                    type="text"
                    name="full_name"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="Full Name"
                    className={styles.inputBox}
                  /> */}
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">{ErrorObject?.email}</span>
                </Col>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    name="company_name"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="Company name"
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.company_name}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.password}
                  </span>
                </Col>
                <Col xs={6}>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    name="confirm_password"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.confirm_password}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  {/* <Form.Select
                    className={styles.inputBox}
                    name="gender"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                  >
                    <option>Gender</option>
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                    <option value="OTHER">OTHER</option>
                  </Form.Select> */}
                  <Form.Control
                    type="date"
                    name="dob"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="dob"
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">{ErrorObject?.dob}</span>
                </Col>
                <Col xs={6}>
                  <Form.Control
                    type="number"
                    name="contact_number"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="(000)-000-0000"
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.contact_number}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  {/* <Form.Select
                    className={styles.inputBox}
                    name="country"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                  >
                    <option>- Country -</option>
                    <option>01</option>
                    <option>02</option>
                  </Form.Select> */}

                  <Select
                    options={options}
                    className={styles.inputBox}
                    placeholder="country"
                    name="country"
                    onChange={(e) => {
                      setSignupdetails((prev) => ({
                        ...prev,
                        country: e.value,
                      }));
                    }}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.country}
                  </span>
                </Col>
                <Col xs={6}>
                  {/* <Form.Select
                    className={styles.inputBox}
                    name="state"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                  >
                    <option>-Select state First-</option>
                    <option>01</option>
                    <option>02</option>
                  </Form.Select> */}

                  <Select
                    options={option}
                    className={styles.inputBox}
                    placeholder="state"
                    name="state"
                    onChange={(e) => {
                      setSignupdetails((prev) => ({
                        ...prev,
                        state: e.value,
                      }));
                    }}
                  />
                  <span className="fs-6 text-danger">{ErrorObject?.state}</span>
                </Col>
              </Row>
              {/* <Row>
                <Col md={12}>
                  <Form.Control
                    type="text"
                    name="address"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="Address"
                    className={styles.inputBox}
                  />
                </Col>
              </Row> */}
              <Row>
                <Col xs={6}>
                  <Form.Control
                    type="text"
                    name="city"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="City"
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">{ErrorObject?.city}</span>
                </Col>
                <Col xs={6}>
                  <Form.Control
                    type="number"
                    name="zipcode"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="Zip Code"
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.zipcode}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Form.Control
                    type="text"
                    name="qualification"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="Qualification"
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.qualification}
                  </span>
                </Col>
              </Row>
              {/* <Row>
               
              </Row> */}

              <Form.Group
                className={`${styles.check} mb-1`}
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  type="checkbox"
                  label="I have read and accepted the Terms and Conditions."
                  name="agree"
                  value="true"
                  onChange={(e) => {
                    onChangeInput(e);
                  }}
                />
              </Form.Group>
              <Row>
                <Col>
                  <Googlemap location={location} zoomLevel={17} />
                </Col>
              </Row>
            </Col>
            <h2 className="mb-3 text-center">
              <span className={styles.trems}>
                <a href="">Terms and Condition | </a>
              </span>
              <span className={styles.trems}>
                <a href="">Privacy Policy</a>
              </span>
            </h2>
            <Button
              className={styles.submitbtn}
              type="submit"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </Button>
          </Form>
        </div>
      </Container>
      <br />
      <br />
      <br />
    </>
  );
};

export default SignupForm;

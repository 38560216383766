import React from "react";

const RestaurentExtraservices = React.lazy(() =>
  import("./pages/restaurent/sidebar/extra-services")
);
const RestaurentSettings = React.lazy(() =>
  import("./pages/restaurent/sidebar/settings")
);
const Restaurentfeaturedmen = React.lazy(() =>
  import("./pages/restaurent/sidebar/featured-men")
);
const Restaurentfeaturedwomen = React.lazy(() =>
  import("./pages/restaurent/sidebar/featured-men")
);
const Restaurentdashboard = React.lazy(() =>
  import("./pages/restaurent/dashboard")
);
const Restaurentprofile = React.lazy(() =>
  import("./pages/restaurent/sidebar/profile")
);
const Restaurentorders = React.lazy(() =>
  import("./pages/restaurent/sidebar/my-orders")
);
const Restaurentrecentllyadded = React.lazy(() =>
  import("./pages/restaurent/sidebar/recentlly-added")
);

const restaurentroutes = [
  {
    path: "/restaurent-profile",
    exact: true,
    name: "Homepage",
    element: Restaurentprofile,
  },
  {
    path: "/restaurent-orders",
    exact: true,
    name: "Homepage",
    element: Restaurentorders,
  },
  {
    path: "/restaurent-recentllyadded",
    exact: true,
    name: "Homepage",
    element: Restaurentrecentllyadded,
  },
  {
    path: "/restaurent-dashboard",
    exact: true,
    name: "Homepage",
    element: Restaurentdashboard,
  },
  {
    path: "/restaurent-featuredmen",
    exact: true,
    name: "Homepage",
    element: Restaurentfeaturedmen,
  },
  {
    path: "/restaurent-featuredwomen",
    exact: true,
    name: "Homepage",
    element: Restaurentfeaturedwomen,
  },
  {
    path: "/restaurent-setting",
    exact: true,
    name: "Homepage",
    element: RestaurentSettings,
  },
  {
    path: "/restaurent-extraservices",
    exact: true,
    name: "Homepage",
    element: RestaurentExtraservices,
  },

  //   { path: "/", exact: true, name: "Homepage", element: Homepage },

  //   {
  //     path: "/edit-profile",
  //     exact: true,
  //     name: "Homepage",
  //     element: Edit_profiles,
  //   },

  //   {
  //     path: "/dashboard_items",
  //     exact: true,
  //     name: "Homepage",
  //     element: DashBoard_itms,
  //   },
  //   { path: "/orders", exact: true, name: "Wonproject", element: Orders },
  //   { path: "/activebids", exact: true, name: "Activebids", element: Activebids },
  //   {
  //     path: "/favourite-drivers",
  //     exact: true,
  //     name: "Favouritedrivers",
  //     element: Favouritedrivers,
  //   },
  //   {
  //     path: "/featured-men",
  //     exact: true,
  //     name: "Featuredmen",
  //     element: Featuredmen,
  //   },
  //   {
  //     path: "/featured-women",
  //     exact: true,
  //     name: "Featuredwomen",
  //     element: Featuredwomen,
  //   },
  //   { path: "/setting", exact: true, name: "Settings", element: Settings },
  //   {
  //     path: "/won-project",
  //     exact: true,
  //     name: "Wonproject",
  //     element: Wonproject,
  //   },
  //   { path: "/past-customer", exact: true, name: "Customer", element: Customer },
  //   { path: "/jobs", exact: true, name: "Jobs", element: Jobs },
  //   {
  //     path: "/proposals",
  //     exact: true,
  //     name: "Modal-proposal",
  //     element: ModalProposal,
  //   },
  //   {
  //     path: "/pending-orders",
  //     exact: true,
  //     name: "Pending-orders",
  //     element: PendingOrder,
  //   },
  //   {
  //     path: "/recently-added",
  //     exact: true,
  //     name: "Recently-added",
  //     element: Recentlyadded,
  //   },
  //   { path: "/services", exact: true, name: "Recently-added", element: Services },
  //   {
  //     path: "/deliverydriver",
  //     exact: true,
  //     name: "Recently-added",
  //     element: Deliverydriver,
  //   },
  //   { path: "/settings", exact: true, name: "Recently-added", element: Settings },
];

export default restaurentroutes;

import React from "react";
import { Container, Button, Form, Col, Row } from "react-bootstrap";
import styles from "../../styles/modules/signup.module.scss";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { Signup } from "../../services/auth.service";
import Googlemap from "../../component/googlemap/map";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import Loader from "../../component/Loader/loader";
import { toast } from "react-toastify";

import Select from "react-select";

import { Validator } from "../../utiles/signup/signup.validator";

import { Country, State, City } from "country-state-city";

const SignupForm = () => {
  const history = useNavigate();
  const [role, setRole] = useState("");
  const [user, setUser] = useState([]);
  const [loaderscreen, setloaderscreen] = useState(false);
  const [profile, setProfile] = useState([]);
  let fcmToken = localStorage.getItem("fcm_token");
  const [Signupdetail, setSignupdetails] = useState({
    // fcm_token: fcmToken,
    agree: false,
  });
  let Country = require("country-state-city").Country;
  let State = require("country-state-city").State;
  const { id } = useParams();
  const [ErrorObject, setErrorObject] = useState({});
  // const options = useMemo(() => countryList().getData(), []);
  // const option = useMemo(
  //   () =>
  //     State.getAllStates().map((items) => {
  //       return {
  //         value: items.name,
  //         label: items.name,
  //       };
  //     }),

  //   []
  // );
  const option = useMemo(
    () =>
      State.getStatesOfCountry(Signupdetail?.country).map((items) => {
        return {
          value: items.name,
          label: items.name,
        };
      }),

    [Signupdetail?.country]
  );

  const options = useMemo(
    () =>
      Country.getAllCountries().map((items) => {
        return {
          value: items.isoCode,
          label: items.name,
        };
      }),

    []
  );

  useMemo(() => {
    if (id == "640ec4c5941ddd210c4dae93") {
      setRole("640ec4c5941ddd210c4dae93");
    } else if (id == "640ec4da941ddd210c4dae98") {
      setRole("640ec4da941ddd210c4dae98");
    } else if (id == "640ec4ec941ddd210c4dae9d") {
      setRole("640ec4ec941ddd210c4dae9d");
    } else if (id == "640ec508941ddd210c4daea7") {
      setRole("640ec508941ddd210c4daea7");
    } else {
      setRole("");
    }
  }, []);
  const Redirecturi = () => {
    if (id == "640ec4c5941ddd210c4dae93") {
      history(`/chef-dashboard`);
    }
    if (id == "640ec4da941ddd210c4dae98") {
      history(`/driver-dashboard`);
    }
    if (id == "640ec4ec941ddd210c4dae9d") {
      history(`/taster-dashboard`);
    }
    if (id == "640ec508941ddd210c4daea7") {
      history(`/grocery-home`);
    }
    if (id == "640ec4f7941ddd210c4daea2") {
      history(`/restaurent-dashboard`);
    }
  };
  const onChangeInput = (e) => {
    e.preventDefault();
    setErrorObject({});
    setSignupdetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const location = {
    // address: "1600 Amphitheatre Parkway, Mountain View, california.",
    address: "malviya nagar jaipur",
    lat: 37.42216,
    lng: -122.08427,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let result = Validator(Signupdetail);
    const { error } = result;

    if (error) {
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        const message = item.message;
        errorData[name] = message;
      }
      setErrorObject(errorData);
      window.scrollTo(100, 100);
      return;
    }

    if (!Signupdetail?.agree) {
      toast.error("Required to  agree its terms and conditions ");
      return;
    }
    let body = {
      role: role,
      company_name: Signupdetail?.company_name,
      address: Signupdetail?.address,
      contact_number: Signupdetail?.contact_number,
      country: Signupdetail?.country,
      full_name: Signupdetail?.full_name,
      email: Signupdetail?.email,
      state: Signupdetail?.state,
      city: Signupdetail?.city,
      password: Signupdetail?.password,
      confirm_password: Signupdetail?.confirm_password,
      gender: Signupdetail?.gender,
      username: Signupdetail?.username,
      dob: Signupdetail?.dob,
      zipcode: Signupdetail?.zipcode,
      // fcm_token: Signupdetail?.fcm_token,
    };
    setloaderscreen(true);
    Signup(body).then((response) => {
      if (response?.success) {
        setloaderscreen(false);
        toast.success("signup successfully");
        history(`/`);
      } else {
        toast.error(response?.message);
        setloaderscreen(false);
      }
    });
  };
  const responseMessage = (response) => {
    toast.success(response);
  };
  const errorMessage = (error) => {
    toast.error("Email address is not correct");
  };
  // useEffect(() => {
  //   if (user) {
  //     axios
  //       .get(
  //         `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${user.access_token}`,
  //             Accept: "application/json",
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         setProfile(res.data);
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, [user]);

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    setProfile(null);
  };
  // const onLoginStart = () => {
  //   alert("login start");
  // };
  // const Redirecturl = "http://localhost:3000/";

  return (
    <>
      <Container className="App">
        {loaderscreen ? <Loader /> : ""}
        <div className={styles.headbody}>
          <h1>Tasters & Foodies</h1>
          <br />
          <div className="d-flex justify-content-center">
            <LoginSocialGoogle
              client_id="1006943610162-1o78nf3t1vkovdi6fdn20pi606skkc92.apps.googleusercontent.com"
              // onLoginStart={onLoginStart}
              // redirect_uri={Redirecturl}
              // scope="openid profile email"
              // discoveryDocs="claims_supported"
              // access_type="offline"
              // onResolve={({ provider, data }: IResolveParams) => {
              //   setProvider(provider);
              //   setProfile(data);
              // }}
              // onReject={(err) => {

              // }}
            >
              <Button
                className={styles.signup}
                size="lg"
                style={{ marginRight: "10px" }}
              >
                Sign Up With Google
              </Button>
            </LoginSocialGoogle>

            <LoginSocialFacebook
              appId="111143426329466"
              fieldsProfile={
                "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
              }
              // onLoginStart={onLoginStart}
              // onLogoutSuccess={onLogoutSuccess}
              // redirect_uri={REDIRECT_URI}
              // onResolve={({ provider, data }: IResolveParams) => {
              //   setProvider(provider);
              //   setProfile(data);
              // }}
              // onReject={(err) => {
              //   console.log(err);
              // }}
            >
              <Button className={styles.signupfacebook} size="lg">
                Sign Up With Facebook
              </Button>
            </LoginSocialFacebook>
            {/* <GoogleLogin
              className={styles.signup}
              onSuccess={responseMessage}
              style={{ marginRight: "10px" }}
              onError={errorMessage}
            /> */}
            {/* <Button
              className={styles.signup}
              size="lg"
              style={{ marginRight: "10px" }}
              onClick={() => login()}
            >
              Sign Up With Google
            </Button> */}
          </div>
        </div>

        <div className={styles.formBody}>
          <h1>PERSONAL DETAILS</h1>
          <p>"Tell us more about yourself"</p>
          <Form>
            <Col className="App">
              <Row>
                <Col xs={6}>
                  <Form.Control
                    type="text"
                    name="username"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="User Name"
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.username}
                  </span>
                </Col>
                <Col xs={6}>
                  <Form.Control
                    type="text"
                    name="full_name"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="Full Name"
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.full_name}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">{ErrorObject?.email}</span>
                </Col>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    placeholder="Company name"
                    name="company_name"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.company_name}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.password}
                  </span>
                </Col>
                <Col xs={6}>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    name="confirm_password"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.confirm_password}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <Form.Select
                    className={styles.inputBox}
                    name="gender"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                  >
                    <option>Gender</option>
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                    <option value="OTHER">OTHER</option>
                  </Form.Select>
                  <span className="fs-6 text-danger">
                    {ErrorObject?.gender}
                  </span>
                </Col>
                <Col xs={6}>
                  <Form.Control
                    type="number"
                    name="contact_number"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="(000)-000-0000"
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.contact_number}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  {/* <Form.Select
                    className={styles.inputBox}
                    name="country"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                  >
                    <option>- Country -</option>
                    <option>01</option>
                    <option>02</option>
                  </Form.Select> */}

                  <Select
                    options={options}
                    className={styles.inputBox}
                    placeholder="country"
                    name="country"
                    onChange={(e) => {
                      setSignupdetails((prev) => ({
                        ...prev,
                        country: e.value,
                      }));
                    }}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.country}
                  </span>
                </Col>
                <Col xs={6}>
                  {/* <Form.Select
                    className={styles.inputBox}
                    name="state"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                  >
                    <option>-Select state First-</option>
                    <option>01</option>
                    <option>02</option>
                  </Form.Select> */}

                  <Select
                    options={option}
                    className={styles.inputBox}
                    placeholder="state"
                    name="state"
                    onChange={(e) => {
                      setSignupdetails((prev) => ({
                        ...prev,
                        state: e.value,
                      }));
                    }}
                  />
                  <span className="fs-6 text-danger">{ErrorObject?.state}</span>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Control
                    type="text"
                    name="address"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="Address"
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.address}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <Form.Control
                    type="text"
                    name="city"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="City"
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">{ErrorObject?.city}</span>
                </Col>
                <Col xs={6}>
                  <Form.Control
                    type="number"
                    name="zipcode"
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="Zip Code"
                    className={styles.inputBox}
                  />
                  <span className="fs-6 text-danger">
                    {ErrorObject?.zipcode}
                  </span>
                </Col>
              </Row>

              <Form.Group
                className={`${styles.check} mb-1`}
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  type="checkbox"
                  label="I have read and accepted the Terms and Conditions."
                  name="agree"
                  checked={Signupdetail?.agree}
                  onChange={(e) => {
                    setSignupdetails((prev) => ({
                      ...prev,
                      agree: !Signupdetail?.agree,
                    }));
                  }}
                />
              </Form.Group>
              <Row>
                <Col>
                  <Googlemap location={location} zoomLevel={17} />
                </Col>
              </Row>
            </Col>
            <h2 className="mb-3 text-center">
              <span className={styles.trems}>
                <a href="">Terms and Condition | </a>
              </span>
              <span className={styles.trems}>
                <a href="">Privacy Policy</a>
              </span>
            </h2>
            <Button
              className={styles.submitbtn}
              type="submit"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </Button>
          </Form>
        </div>
      </Container>
      <br />
      <br />
      <br />
    </>
  );
};

export default SignupForm;

import { useMemo, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useContext } from "react";
import Row from "react-bootstrap/Row";

import styles from "../../styles/modules/offcanva.module.scss";
import {
  getCartlist,
  getUpdateCart,
  getdeletecart,
  getcartDetails,
} from "../../services/taster-module/taster-dashboard";
import { Cartcontext } from "../../context-cart/context";
import { set } from "date-fns";
import { toast } from "react-toastify";
import { decryptData } from "../../helper";

function OffCanvasExample({ name, ...props }) {
  const [show, setShow] = useState(false);
  const [cartdetails, setCartdetailss] = useState("");
  const [usersid, setuserid] = useState("");
  const Globalstate = useContext(Cartcontext);

  // context global state and dispatch
  const dispatch = Globalstate.dispatch;
  const state = Globalstate.state;
  console.log(state, "stateeekkjjkjjkjkjkjkjks");
  // cart of array of common chef
  var tempstate1 = [];
  state?.map((items, index) => {
    const tempstate = state.filter(
      (item) => item?.dish_id?.user_id?._id === items?.dish_id?.user_id?._id
    );
    let x = {
      chef_id: tempstate[0].dish_id?.user_id?._id,
      chef_name: tempstate[0]?.dish_id?.user_id?.full_name,
      total_cart_item: tempstate,
    };
    tempstate1 = [x];
  });
  // cart of uncommon array
  var tempstate2 = [];
  state?.map((items, index) => {
    const tempstate3 = state.filter(
      (item) => item?.dish_id?.user_id?._id !== items?.dish_id?.user_id?._id
    );

    let x = {
      // user_id: state[index].dish_id?.user_id?._id,
      chef_id: tempstate3[0]?.dish_id?.user_id?._id,
      chef_name: tempstate3[0]?.dish_id?.user_id?.full_name,
      total_cart_item: tempstate3,
    };
    tempstate2 = [x];
  });
  // concact of common and uncommon chef
  const Finalarray = [...tempstate2, ...tempstate1];

  // All carts checkout total
  const total = state.reduce((total, item) => {
    return total + item?.dish_id?.cost * item?.quantity;
  }, 0);

  //   Carts subtotal
  const Subtotals = Finalarray?.map((item) => {
    let sum = 0;
    item?.total_cart_item.forEach((items) => {
      sum += items?.quantity * items?.dish_id?.cost;
    });

    return sum;
  });
  // get user id from local storage
  useMemo(() => {
    decryptData().then((response) => {
      if (response) {
        setuserid(response?._id);
      }
    });
  }, [usersid]);
  // get cart detail and push into global state

  useMemo(() => {
    getcartDetails(usersid).then((response) => {
      if (response?.success) {
        let records = response?.data?.cart_item;
        var cartid = response?.data?._id;

        setCartdetailss(cartid);
        let action = {
          type: "UPDATESTATE",
          payload: records,
        };
        dispatch(action);
      }
    });
  }, []);

  const open = () => {
    setShow(!show);
  };

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        onClick={open}
        fill="currentColor"
        className="bi bi-cart text-dark"
        viewBox="0 0 16 16"
      >
        <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
      </svg>
      <Offcanvas
        show={show}
        onHide={open}
        {...props}
        style={{ width: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div style={{
              display:"flex",alignItems:"center",
              justifyContent:"space-around"
            }}>
              <div>
                <img src="../images/cardheader.png" />
              </div>
            <div>
            <p
                style={{
                  fontSize: "24px",
                  color: "#428bca",
                  fontWeight: "700",
                }}
              >
                My Grocery Cart
              </p>
            </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="cart">
            {Finalarray.map((items, index) => {
              return (
                <>
                  <div
                    className="card mb-5"
                    key={index}
                    style={{ border: "none" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className="d-flex align-items-center">
                          <div className={styles.mainimg}>
                            <img className="rounded-circle" src="" alt="" />
                          </div>
                          <div className="ms-3 d-flex justify-content-start">
                            <span>{items?.chef_name}</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        {state.length > 0 && (
                          <div className="total">
                            <span>${Subtotals[index]}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <hr></hr>
                    {items?.total_cart_item?.map((item) => {
                      return (
                        <>
                          <div className="">
                            <div
                              className="d-flex justify-content-between align-items-center"
                              key={index}
                            >
                              <div>
                                <div className="d-flex align-items-center">
                                  <div className={styles.mainimg}>
                                    <span>Dish</span>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <span className="ms-5">Qty</span>
                              </div>
                              <div>
                                <span>Price</span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-3">
                              <div>
                                <div className="d-flex align-items-center">
                                  <div className={styles.mainimg}>
                                    <img
                                      className="rounded-circle"
                                      src={item?.dish_id?.dish_photo}
                                      alt=""
                                    />
                                  </div>
                                  <div className="ms-3">
                                    <span>{item?.dish_id?.dish_title}</span>
                                    <br></br>
                                    <span
                                      onClick={() => {
                                        dispatch({
                                          type: "REMOVE",
                                          payload: item,
                                        });
                                      }}
                                      className="text-danger"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-trash text-danger"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                      </svg>{" "}
                                      Remove
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <span className="me-3">
                                  <div className={styles.quantity}>
                                    <div
                                      className="qty-dec cart-qty-change"
                                      onClick={() => {
                                        if (item?.quantity > 1) {
                                          dispatch({
                                            type: "DECREASE",
                                            payload: item,
                                          });
                                        } else {
                                          dispatch({
                                            type: "REMOVE",
                                            payload: item,
                                          });
                                        }
                                      }}
                                    >
                                      <i className="fa fa-minus pe-2"></i>
                                    </div>
                                    <input
                                      type="number"
                                      className="ci-quantity form-control text-center user-select-none"
                                      value={item?.quantity}
                                      placeholder="Quantity"
                                      data-item="823"
                                      min="1"
                                    />
                                    <div
                                      className="qty-inc cart-qty-change"
                                      onClick={() => {
                                        dispatch({
                                          type: "INCREASE",
                                          payload: item,
                                        });
                                      }}
                                    >
                                      <i className="fa fa-plus ps-2"></i>
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div>
                                {/* <span>${items.quantity * items.cost}</span>  */}
                                <span className="text-success fw-bold fs-5">
                                  ${item?.dish_id?.cost}
                                </span>
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                        </>
                      );
                    })}
                  </div>
                </>
              );
            })}
            {/* {state.length > 0 && (
              <div className="total">
                <h2>{total}</h2>
              </div>
            )} */}
          </div>
          {!state.length == 0 ? (
            <>
              <div className={styles.cart}>
                <button className={styles.buttones}>
                  <a
                    href={`/dish/check-out/${cartdetails}`}
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Checkout
                    <span className={styles.total}>
                      {" "}
                      {state.length > 0 && (
                        // <div className="total">
                        <h2>${total}</h2>
                        // </div>
                      )}
                    </span>
                  </a>{" "}
                </button>
              </div>
            </>
          ) : (
            " "
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffcanvasModal(props) {
  return (
    <>
      <OffCanvasExample
        // key={props.key}
        placement={props.placement}
        name={props.name}
      />
    </>
  );
}

export default OffcanvasModal;

import $ from "jquery";
import { useEffect } from "react";
const Loader = () => {
  return (
    <>
      <div className="loading"></div>
    </>
  );
};
export default Loader;

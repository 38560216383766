import React, { Suspense, useState, useMemo } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import driverroutes from "../ driverroutes";
import restaurentroutes from "../restaurentroutes";
import groceryroutes from "../groceryroutes";
import chefroutes from "../chefroutes";
import defaultroutes from "../defaultroute";
import tasterroutes from "../tasterroutes";
import Homepage from "../pages/homepage/index";
import { useUserState } from "../context/auth.context";
import { decryptData } from "../helper";
import { useStateContext } from "../context-loader/loader.context";
import Loader from "../component/Loader/loader";

const Loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Content = () => {
  const [role, setrole] = useState("");
  const [routeset, setrouteset] = useState([]);
  const { PageLoading } = useStateContext();
  var { isAuthenticated } = useUserState();
  const Routeseset = () => {
    if (isAuthenticated && role == "640ec4c5941ddd210c4dae93") {
      setrouteset(chefroutes);
    } else if (isAuthenticated && role == "640ec4ec941ddd210c4dae9d") {
      setrouteset(tasterroutes);
    } else if (isAuthenticated && role == "640ec4f7941ddd210c4daea2") {
      setrouteset(restaurentroutes);
    } else if (isAuthenticated && role == "640ec4da941ddd210c4dae98") {
      setrouteset(driverroutes);
    } else if (isAuthenticated && role == "640ec508941ddd210c4daea7") {
      setrouteset(groceryroutes);
    }
  };
  useMemo(() => {
    // const routesflow = async () => {

    decryptData().then((response) => {
      if (response) {
        setrole(response?.role?._id);

        Routeseset();
      }
    });
    // };
  }, [role]);

  return (
    <>
      <div>
        {PageLoading && <Loader />}
        <Suspense fallback={Loader}>
          <Routes>
            {/* {role !== <></>} */}
            {routeset.map((route, idx) => {
              return (
                route.element && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.element />}
                  />
                )
              );
            })}

            <Route
              path="/"
              exact="true"
              name="homepage"
              element={<Homepage />}
            />
          </Routes>
        </Suspense>
      </div>
    </>
  );
};

export default React.memo(Content);

import { useState, useEffect, useMemo } from "react";
import styles from "../../styles/modules/member-box.module.scss";

const MemberBox = ({ variant }) => {
  return (
    <div className={styles.memberBox}>
      <a href="">
        <div className={styles.profileImg}>
          <img src="images/profile.png" alt="" />{" "}
          <span className={styles.premium_img}>
            <img src="/images/crown.png" className="premium" />
          </span>
        </div>
        <h3>Tony Parker</h3>
        <p>
          <img src="/images/blank.gif" className="flag home-flag flag-us" />{" "}
          India
        </p>
        <h4>Mansarovar, Jaipur</h4>
      </a>
    </div>
  );
};
export default MemberBox;

import React from "react";

const Deliverydriver = React.lazy(() =>
  import("./pages/sidebar/deliverydriver/index")
);

const Driverprofile = React.lazy(() => import("./pages/driver/profile"));
const Groceryprofile = React.lazy(() =>
  import("./pages/Grocery/grocery_profile")
);
const Driverdashboard = React.lazy(() => import("./pages/driver/dashboard"));

const Driverfeaturedman = React.lazy(() =>
  import("./pages/driver/sidebar/featuredman")
);
const Driverfeaturedwomanman = React.lazy(() =>
  import("./pages/driver/sidebar/featuredwoman")
);

const Driverdeliverorders = React.lazy(() =>
  import("./pages/driver/sidebar/grocerydeliversorders")
);

const Driverstoreorders = React.lazy(() =>
  import("./pages/driver/sidebar/grocerystoreorders")
);
const Driverstorerecentlyadded = React.lazy(() =>
  import("./pages/driver/sidebar/recentlyadded")
);
const Driverorders = React.lazy(() => import("./pages/driver/sidebar/orders"));

const Driverservices = React.lazy(() =>
  import("./pages/driver/sidebar/services")
);

const Driversetting = React.lazy(() =>
  import("./pages/driver/sidebar/setting")
);

const EditprofileTab = React.lazy(() =>
  import("./pages/driver/sidebar/edit-profiletab")
);
const Viewdetails = React.lazy(() =>
  import("./component/driversTab/dashboardtab/view-detail")
);
const Orderdetail = React.lazy(() =>
  import("./component/driversTab/dashboardtab/order-detail")
);
const driverroutes = [
  {
    path: "/deliverydriver",
    exact: true,
    name: "Recently-added",
    element: Deliverydriver,
  },
  {
    path: "/edit-driver-profile",
    exact: true,
    name: "EditprofileTab",
    element: EditprofileTab,
  },

  {
    path: "/driver-profile",
    exact: true,
    name: "Driverprofile",
    element: Driverprofile,
  },
  {
    path: "/driver-dashboard",
    exact: true,
    name: "Driverdashboard",
    element: Driverdashboard,
  },

  {
    path: "/driver-featured-man",
    exact: true,
    name: "Driverfeaturedman",
    element: Driverfeaturedman,
  },
  {
    path: "/driver-featured-woman",
    exact: true,
    name: "Driverfeaturedwomanman",
    element: Driverfeaturedwomanman,
  },
  {
    path: "/driver-delivered-order",
    exact: true,
    name: "Driverdeliverorders",
    element: Driverdeliverorders,
  },
  {
    path: "/driver-store-order",
    exact: true,
    name: "Driverstoreorders",
    element: Driverstoreorders,
  },
  {
    path: "/driver-recentely-added",
    exact: true,
    name: "Driverstorerecentlyadded",
    element: Driverstorerecentlyadded,
  },
  {
    path: "/driver-orders",
    exact: true,
    name: "Driverorders",
    element: Driverorders,
  },
  {
    path: "/driver-services",
    exact: true,
    name: "Driverservices",
    element: Driverservices,
  },
  {
    path: "/driver-setting",
    exact: true,
    name: "Driversetting",
    element: Driversetting,
  },

  {
    path: "/order-view-details",
    exact: true,
    name: "view-detail",
    element: Viewdetails,
  },

  {
    path: "/driver-order-details",
    exact: true,
    name: "order-details",
    element: Orderdetail,
  },

];

export default driverroutes;

import React from "react";

const Homepage = React.lazy(() => import("./pages/homepage/index"));

const Profile = React.lazy(() => import("./pages/chef/Profile"));

const Chefdashboard = React.lazy(() => import("./pages/chef/dashboard"));
const ChefOrderviewdetails = React.lazy(() =>
  import("./pages/chef/order-details")
);

const Activebids = React.lazy(() => import("./pages/sidebar/activebids/index"));

// const DashBoard_itms = React.lazy(() =>
//   import("./pages/taster-dashboard/DashBoard_items/items.js")
// );
const Favouritedrivers = React.lazy(() =>
  import("./pages/sidebar/favouritedrivers/index")
);
const Featuredmen = React.lazy(() =>
  import("./pages/sidebar/featuredmen/index")
);
const Featuredwomen = React.lazy(() =>
  import("./pages/sidebar/featuredwoman/index")
);
const Wonproject = React.lazy(() => import("./pages/sidebar/wonproject/index"));
const Orders = React.lazy(() => import("./pages/sidebar/orders/index"));
const Customer = React.lazy(() => import("./pages/sidebar/pastcustomer/index"));
const Jobs = React.lazy(() => import("./pages/sidebar/jobs/index"));
const ModalProposal = React.lazy(() =>
  import("./pages/sidebar/proposals/index")
);
const Recentlyadded = React.lazy(() =>
  import("./pages/sidebar/recentlyadded/index")
);
const PendingOrder = React.lazy(() =>
  import("./pages/sidebar/pendingorders/index")
);
const Services = React.lazy(() => import("./pages/sidebar/services/index"));
const Settings = React.lazy(() => import("./pages/sidebar/settings/index"));
const Deliverydriver = React.lazy(() =>
  import("./pages/sidebar/deliverydriver/index")
);
const ShopnCook = React.lazy(() => import("./pages/sidebar/shopncook/index"));
const Favouritecoustmer = React.lazy(() =>
  import("./pages/sidebar/favouritecustomers/index")
);

const Edit_profiles = React.lazy(() => import("./pages/chef/edit-profile"));

const Orderviewdetails = React.lazy(() =>
  import("./pages/driver/sidebar/viewdetails")
);

const Membership = React.lazy(() => import("./pages/chef/membership"));

const chefroutes = [
  {
    path: "/chef-dashboard",
    exact: true,
    name: "Restaurentdashboard",
    element: Chefdashboard,
  },
  { path: "/", exact: true, name: "Homepage", element: Homepage },

  {
    path: "/edit-profile",
    exact: true,
    name: "Homepage",
    element: Edit_profiles,
  },
  { path: "/chef-profile", exact: true, name: "Homepage", element: Profile },

  // {
  //   path: "/dashboard_items",
  //   exact: true,
  //   name: "Homepage",
  //   element: DashBoard_itms,
  // },
  { path: "/orders", exact: true, name: "Wonproject", element: Orders },
  { path: "/activebids", exact: true, name: "Activebids", element: Activebids },
  {
    path: "/favourite-drivers",
    exact: true,
    name: "Favouritedrivers",
    element: Favouritedrivers,
  },
  {
    path: "/featured-men",
    exact: true,
    name: "Featuredmen",
    element: Featuredmen,
  },
  {
    path: "/featured-women",
    exact: true,
    name: "Featuredwomen",
    element: Featuredwomen,
  },
  { path: "/setting", exact: true, name: "Settings", element: Settings },
  {
    path: "/won-project",
    exact: true,
    name: "Wonproject",
    element: Wonproject,
  },
  { path: "/past-customer", exact: true, name: "Customer", element: Customer },
  { path: "/jobs", exact: true, name: "Jobs", element: Jobs },
  {
    path: "/proposals",
    exact: true,
    name: "Modal-proposal",
    element: ModalProposal,
  },
  {
    path: "/pending-orders",
    exact: true,
    name: "Pending-orders",
    element: PendingOrder,
  },
  {
    path: "/recently-added",
    exact: true,
    name: "Recently-added",
    element: Recentlyadded,
  },
  { path: "/services", exact: true, name: "Recently-added", element: Services },
  {
    path: "/deliverydriver",
    exact: true,
    name: "Recently-added",
    element: Deliverydriver,
  },
  { path: "/settings", exact: true, name: "Recently-added", element: Settings },
  { path: "/shopn-n-cook", exact: true, name: "Shopncook", element: ShopnCook },
  {
    path: "/favourite-coustmer",
    exact: true,
    name: "Shopncook",
    element: Favouritecoustmer,
  },

  {
    path: "/order-view-details",
    exact: true,
    name: "Orderviewdetails",
    element: Orderviewdetails,
  },
  {
    path: "/order-viewdetails/:id",
    exact: true,
    name: "ChefOrderviewdetails",
    element: ChefOrderviewdetails,
  },

  {
    path: "/membership",
    exact: true,
    name: "Membership",
    element: Membership,
  },
];

export default chefroutes;

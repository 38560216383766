import Joi from "joi-browser";
var Schema = Joi.object().keys({
  company_name: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Company name is required.",
      };
    }),

  address: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Address is required.",
      };
    }),

  gender: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Gender is required.",
      };
    }),
  email: Joi.string()
    .email()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.type) {
          case "any.empty":
            err.message = "Email should not be empty!";
            break;
          case "string.required":
            err.message = `Email required.`;
            break;
          case "string.regex.name":
            err.message = `Email is not valid.`;
            break;
          default:
            err.message = "Email is not valid.";
            break;
        }
      });
      return errors;
    }),
  password: Joi.string()
    .min(8)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.type) {
          case "any.empty":
            err.message = "Password should not be empty!";
            break;
          case "string.min":
            err.message = `Password have at least 8 character!`;
            break;
          case "string.max":
            err.message = `Password should have at most 30 character!`;
            break;
          case "string.required":
            err.message = `Password required.`;
            break;
          case "string.regex.name":
            err.message = `Password should be alphacharacter only.`;
            break;
          default:
            err.message = `Password is not valid.`;
            break;
        }
      });
      return errors;
    }),

  confirm_password: Joi.string()
    .min(8)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.type) {
          case "any.empty":
            err.message = "Confirm password should not be empty!";
            break;
          case "string.min":
            err.message = `Confirm password have at least 8 character!`;
            break;
          case "string.max":
            err.message = `Confirm password should have at most 30 character!`;
            break;
          case "string.required":
            err.message = `Confirm password required.`;
            break;
          case "string.regex.name":
            err.message = `Confirm password should be character only.`;
            break;
          default:
            err.message = `Confirm password is not valid.`;
            break;
        }
      });
      return errors;
    }),
  contact_number: Joi.string()
    .regex(/^[0-9]*$/)
    .min(10)
    .max(10)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.type) {
          case "any.empty":
            err.message = "Mobile should not be empty!";
            break;
          case "string.required":
            err.message = `Mobile required.`;
            break;
          case "string.regex.name":
            err.message = `Mobile should be number only.`;
            break;
          case "string.min":
            err.message = `Mobile must be a 10 digit!`;
            break;
          case "string.max":
            err.message = `Mobile must be a 10 digit`;
            break;
          case "string.regex.base":
            err.message = `Mobile must be a number only.`;
            break;
          default:
            err.message = `Mobile is not valid.`;
            break;
        }
      });
      return errors;
    }),

  address: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Address is required.",
      };
    }),
  country: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Country is required.",
      };
    }),
  state: Joi.string()
    .required()
    .error(() => {
      return {
        message: "State is required.",
      };
    }),
  city: Joi.string()
    .required()
    .error(() => {
      return {
        message: "City is required.",
      };
    }),
  zipcode: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Zipcode is required.",
      };
    }),
  username: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Username is required.",
      };
    }),
  full_name: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Full name  is required.",
      };
    }),
  // full_name: Joi.string()
  //   .regex(/^[a-zA-Z]*$/)
  //   .required()
  //   .error((errors) => {
  //     errors.forEach((err) => {
  //       switch (err.type) {
  //         case "any.empty":
  //           err.message = "Full name should not be empty!";
  //           break;
  //         case "string.required":
  //           err.message = ` Full name should not be empty! is  required.`;
  //           break;
  //         case "string.regex.base":
  //           err.message = `Full name should not be empty! should have character only.`;
  //           break;
  //         default:
  //           err.message = `Full name should not be empty! is required.`;
  //           break;
  //       }
  //     });
  //     return errors;
  //   }),
});

const Validator = (customer) => {
  const result = Joi.validate(customer, Schema, {
    abortEarly: false,
    allowUnknown: true,
  });
  return result;
};
export { Validator };

import React, { useState, useEffect, useCallback } from "react";
import { PrevButton, NextButton } from "../slider-button1/index";
import useEmblaCarousel from "embla-carousel-react";

const ClientSlider = ({ slides, data }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    dragFree: true,
    containScroll: "trimSnaps",
    loop: true,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    onSelect();
  }, [embla, onSelect]);
  return (
    <div className="clientSlider">
      <div className="container position-relative">
        <h1 className="headingssss">
          Client's
          <span>Speak</span>
        </h1>
        <div ref={viewportRef} className="embla__viewport">
          <div className="embla__container">
            <div className="sliderInner">
              <div className="slider-left-content">
                <p className="slider-title">
                  cook is testyLorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat
                </p>
                <div className="testi-content">
                  <img src="images/default_user_f.png" />
                  <p className="slider-nam">Tyson Mickey</p>

                  <p className="slider-role">Ceo &amp; Founder founder</p>
                </div>
              </div>
            </div>
            <div className="sliderInner">
              <div className="slider-left-content">
                <p className="slider-title">
                  cook is testyLorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat
                </p>
                <div className="testi-content">
                  <img src="images/default_user_f.png" />
                  <p className="slider-nam">Tyson Mickey</p>

                  <p className="slider-role">Ceo &amp; Founder founder</p>
                </div>
              </div>
            </div>
            <div className="sliderInner">
              <div className="slider-left-content">
                <p className="slider-title">
                  cook is testyLorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat
                </p>
                <div className="testi-content">
                  <img src="images/default_user_f.png" />
                  <p className="slider-nam">Tyson Mickey</p>

                  <p className="slider-role">Ceo &amp; Founder founder</p>
                </div>
              </div>
            </div>
            <div className="sliderInner">
              <div className="slider-left-content">
                <p className="slider-title">
                  cook is testyLorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat
                </p>
                <div className="testi-content">
                  <img src="images/default_user_f.png" />
                  <p className="slider-nam">Tyson Mickey</p>

                  <p className="slider-role">Ceo &amp; Founder founder</p>
                </div>
              </div>
            </div>
            <div className="sliderInner">
              <div className="slider-left-content">
                <p className="slider-title">
                  cook is testyLorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat
                </p>
                <div className="testi-content">
                  <img src="images/default_user_f.png" />
                  <p className="slider-nam">Tyson Mickey</p>

                  <p className="slider-role">Ceo &amp; Founder founder</p>
                </div>
              </div>
            </div>
            <div className="sliderInner">
              <div className="slider-left-content">
                <p className="slider-title">
                  cook is testyLorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat
                </p>
                <div className="testi-content">
                  <img src="images/default_user_f.png" />
                  <p className="slider-nam">Tyson Mickey</p>

                  <p className="slider-role">Ceo &amp; Founder founder</p>
                </div>
              </div>
            </div>
            <div className="sliderInner">
              <div className="slider-left-content">
                <p className="slider-title">
                  cook is testyLorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat
                </p>
                <div className="testi-content">
                  <img src="images/default_user_f.png" />
                  <p className="slider-nam">Tyson Mickey</p>

                  <p className="slider-role">Ceo &amp; Founder founder</p>
                </div>
              </div>
            </div>
            <div className="sliderInner">
              <div className="slider-left-content">
                <p className="slider-title">
                  cook is testyLorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat
                </p>
                <div className="testi-content">
                  <img src="images/default_user_f.png" />
                  <p className="slider-nam">Tyson Mickey</p>

                  <p className="slider-role">Ceo &amp; Founder founder</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
      </div>
    </div>
  );
};
export default ClientSlider;

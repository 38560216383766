import Joi from "joi-browser";
var Schema = Joi.object().keys({
  email: Joi.string()
    .email()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.type) {
          case "any.empty":
            err.message = "Email should not be empty!";
            break;
          case "string.required":
            err.message = `Email required.`;
            break;
          case "string.regex.name":
            err.message = `Email is not valid.`;
            break;
          default:
            err.message = "Email is not valid.";
            break;
        }
      });
      return errors;
    }),
  password: Joi.string()
    .min(8)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.type) {
          case "any.empty":
            err.message = "Password should not be empty!";
            break;
          case "string.min":
            err.message = `Password have at least 8 character!`;
            break;
          case "string.max":
            err.message = `Password should have at most 30 character!`;
            break;
          case "string.required":
            err.message = `Password required.`;
            break;
          case "string.regex.name":
            err.message = `Password should be alphacharacter only.`;
            break;
          default:
            err.message = `Password is not valid.`;
            break;
        }
      });
      return errors;
    }),
});

const Validator = (customer) => {
  const result = Joi.validate(customer, Schema, {
    abortEarly: false,
    allowUnknown: true,
  });
  return result;
};
export { Validator };

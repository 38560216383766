import CryptoJS from "crypto-js";

const secretPass = "PMTC-xytrefhnbgtysderbsoy";

export const encryptData = (detail) => {
  let tokens = detail?.token;
  let users = detail?.user;
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(tokens),
    secretPass
  ).toString();
  const userdata = CryptoJS.AES.encrypt(
    JSON.stringify(users),
    secretPass
  ).toString();

  sessionStorage.setItem("token_key", data);
  sessionStorage.setItem("users", userdata);
  return true;
};

export const decryptData = async () => {
  let users = sessionStorage.getItem("users");
  const bytes = CryptoJS.AES.decrypt(users, secretPass);
  const data = await JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return data;
};

export const decryptToken = async () => {
  let token = sessionStorage.getItem("token_key");

  const bytes = CryptoJS.AES.decrypt(token, secretPass);
  const data = await JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return data;
};

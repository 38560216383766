import Header from "../Header/index";
import Innerpages from "../../pages/index";
import Footer from "../Footer/index";
import { decryptData } from "../../helper";
import { useEffect, useState, useMemo } from "react";
import InnerHeader from "../header";
const Layout = () => {
  const [role, setrole] = useState();
  useMemo(() => {
    decryptData().then((response) => {
      if (response) {
        setrole(response?.role?._id);
      }
    });
  }, [role]);

  return (
    <>
      {role ? <InnerHeader></InnerHeader> : <Header />}

      <Innerpages />
      <Footer />
    </>
  );
};
export default Layout;

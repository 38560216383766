/** @format */

import { createContext, useReducer, useState, useEffect, useMemo } from "react";
import {
  addcartrequest,
  getUpdateCart,
  getdeletecart,
} from "../services/taster-module/taster-dashboard";
import { toast } from "react-toastify";

import { decryptData } from "../helper";

export const Cartcontext = createContext();
export const Context = (props) => {
  const [usersid, setuserid] = useState("");
  useMemo(() => {
    decryptData().then((response) => {
      if (response) {
        setuserid(response?._id);
      }
    });
  }, [usersid]);
  const reducer = (state, action) => {
    console.log(state, "statekkjkkjj");
    switch (action.type) {
      case "ADD":
        const tempstate = state.filter(
          (item) => item?.dish_id?._id === action?.payload?._id
        );
        // const tempstate = state.filter((item) => action.payload.id === item.id);

        // const tempstate = [...state];
        if (tempstate.length > 0) {
          var tempstate1 = [];
          state.map((item) => {
            if (item?.dish_id?._id === action?.payload?._id) {
              getUpdateCart({
                user_id: usersid,
                dish_id: action.payload?._id,
                quantity: item.quantity + 1,
              }).then((response) => {
                if (response?.success) {
                  toast.success("cart list has been updated");
                }
              });

              tempstate1.push({ ...item, quantity: item.quantity + 1 });
            } else {
              tempstate1.push({ ...item, quantity: item.quantity });
            }
          });

          return tempstate1;
          // return state;
        } else {
          addcartrequest({
            user_id: usersid,
            cart_item: [
              {
                dish_id: action.payload?._id,
                quantity: action.payload?.quantity,
              },
            ],
          }).then((response) => {
            if (response?.success) {
              toast.success("Item has been send successfully");
            }
          });
          return [...state, action.payload];
        }
      case "UPDATESTATE":
        if (action?.payload) {
          action?.payload?.map((item) => state.push(item));
          return [...state];
          // return state;
        }

      case "INCREASE":
        const tempstate3 = state?.map((items) => {
          if (items?.dish_id?._id === action?.payload?.dish_id?._id) {
            getUpdateCart({
              user_id: usersid,
              dish_id: action.payload?.dish_id?._id,
              quantity: action?.payload?.quantity - 1,
            }).then((response) => {
              if (response?.success) {
                // toast.success("cart list has been updated");
              }
            });

            return { ...items, quantity: action?.payload?.quantity + 1 };
          } else {
            return items;
          }
        });

        return tempstate3;

      case "DECREASE":
        const tempstate2 = state?.map((items) => {
          if (items?.dish_id?._id === action?.payload?.dish_id?._id) {
            getUpdateCart({
              user_id: usersid,
              dish_id: action.payload?.dish_id?._id,
              quantity: action?.payload?.quantity - 1,
            }).then((response) => {
              if (response?.success) {
                // toast.success("Quantity of the item decr");
                // getlistdata();
              }
            });

            return { ...items, quantity: action?.payload?.quantity - 1 };
          } else {
            return items;
          }
        });

        return tempstate2;

      case "REMOVE":
        const tempstate4 = state.filter(
          (item) => item?.dish_id?._id !== action?.payload?.dish_id?._id
        );
        getdeletecart({
          user_id: usersid,
          dish_id: action?.payload?.dish_id?._id,
        }).then((response) => {
          if (response?.success) {
            toast.success("item has been removed");
          }
        });

        return tempstate4;

      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, []);
  const info = { state, dispatch };
  return (
    <Cartcontext.Provider value={info}>{props.children}</Cartcontext.Provider>
  );
};

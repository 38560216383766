import { useState, useEffect } from "react";
import Signupmodel from "../sign-upmodel";
import styles from "../../styles/modules/header.module.scss";
import Login from "../login";
import { Loginapi } from "../../services/auth.service";
import Help from "../../component/help/helpmodel";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useStateContext } from "../../context-loader/loader.context";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Validator } from "../../utiles/login.validator";
import { useUserDispatch } from "../../context/auth.context";
import { decryptData, encryptData } from "../../helper";
import OffcanvasModal from "../offcanvas-checkout";

const Header = ({ variant }) => {
  const userDispatch = useUserDispatch();
  const { setPageLoading, PageLoading } = useStateContext();
  const handleClose = () => setVisible(false);
  const [show, setShow] = useState(false);
  const [appear, setAppear] = useState(false);

  const [visible, setVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // login states
  const history = useNavigate();

  const [Logindetail, setLogindetails] = useState({});
  const [ErrorObject, setErrorObject] = useState({});
  const onChangeInput = (e) => {
    e.preventDefault();
    setErrorObject("");

    setLogindetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let result = Validator(Logindetail);
    const { error } = result;

    if (error) {
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        const message = item.message;
        errorData[name] = message;
      }
      setErrorObject(errorData);
      window.scrollTo(100, 100);
      return;
    }
    setPageLoading(true);
    Loginapi(Logindetail).then(async (response) => {
      if (response?.success) {
        toast.success("logged in successfully");

        setShow(false);
        let data = response?.data;
        let encryptRes = encryptData(data);

        userDispatch({ type: "LOGIN_SUCCESS" });
        let detailsData = await decryptData();
        setPageLoading(false);
        if (detailsData?.role?._id == "640ec4c5941ddd210c4dae93") {
          history(`/chef-dashboard`);
        }
        if (detailsData?.role?._id == "640ec4da941ddd210c4dae98") {
          history(`/driver-dashboard`);
        }
        if (detailsData?.role?._id == "640ec4ec941ddd210c4dae9d") {
          history(`/taster-dashboard`);
        }
        if (detailsData?.role?._id == "640ec508941ddd210c4daea7") {
          history(`/grocery-home`);
        }
        if (detailsData?.role?._id == "640ec4f7941ddd210c4daea2") {
          history(`/restaurent-dashboard`);
        }
      } else {
        toast.error(response?.title);
        setPageLoading(false);
        // setShow(false);
      }
    });
  };

  return (
    <>
      {" "}
      <Login
        show={show}
        setShow={setShow}
        handleSubmit={handleSubmit}
        onChangeInput={onChangeInput}
        ErrorObject={ErrorObject}
      />
      {/* <Help visible={visible} setshow={setVisible} /> */}
      <Signupmodel setAppear={setAppear} appear={appear} />
      <div className={styles.header}>
        <div className="container">
          <div className="row align-items-center">
            <div className=" col-lg-2 col-md-3 col-sm-3 col-4 me-5">
              <div className={styles.logo}>
                <a>
                  <img src="../images/logo.png" alt="" />
                </a>
              </div>
            </div>{" "}
            <div className="col-xl-6 col-lg-8 col-sm-7 col-md-8 mt-2">
              <div className={styles.headerSearch}>
                <select
                  className="form-select "
                  aria-label="Default select example"
                >
                  <option className="me-5" defaultValue>
                    All category{" "}
                  </option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <input type="text" />
                <button>
                  <img src="../images/search.svg" alt="" />
                </button>
              </div>
            </div>
            <div className="col-8 col-md-1 d-block d-xl-none col-sm-2 mt-4">
              {" "}
              <div
                className={`${styles["nav"]} ${
                  isMenuOpen ? styles.active : null
                }`}
              >
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setAppear(true);
                  }}
                >
                  Sign Up
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(!show);
                  }}
                >
                  Login
                </a>
                {/* <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(!show);
                  }}
                >
                  Cart
                </a> */}

                <a
                  href="#"
                  className={styles.ask}
                  onClick={(e) => {
                    e.preventDefault();
                    setVisible(!visible);
                  }}
                >
                  Ask for Help?
                </a>
              </div>{" "}
              <div
                className={`${styles["menu-trigger"]} ${
                  isMenuOpen ? styles.active : null
                }`}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <div className={styles.line}></div>
                <div className={styles.line}></div>
                <div className={styles.line}></div>
              </div>
            </div>
            <div className="col-md-4 col-xl-3 col-lg-4 d-none d-xl-block text-end">
              <div className={styles.navLink}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setAppear(!appear);
                  }}
                >
                  Sign Up
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(!show);
                  }}
                >
                  Login
                </a>
                {/* <a
                  href="#"
                  onClick={() => {
                    OffcanvasModal()
                  }}
                >
                  Cart
                </a> */}

                <a
                  href="#"
                  className={styles.ask}
                  onClick={(e) => {
                    e.preventDefault();
                    setVisible(!visible);
                  }}
                >
                  <img src="images/question-mark.svg" alt="" />
                  Ask for Help?
                </a>
              </div>
            </div>
          </div>
          <Modal
            show={visible}
            size="lg"
            onHide={handleClose}
            className="modal show"
          >
            <Modal.Header closeButton>
              <Modal.Title className={styles.modeltitle}>
                Ask for help
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  controlId="formBasicEmail"
                  className={`${styles.formBox} mb-3 `}
                >
                  <Row>
                    <Col md={6}>
                      <Form.Label className={styles.head}>First Name </Form.Label>
                      <Form.Control
                        className={styles.label}
                        type="text"
                        placeholder=""
                      />
                    </Col>

                    <Col md={6}>
                    <Form.Label className={styles.head}>Last Name </Form.Label>
                      <Form.Control
                        className={styles.label}
                        type="text"
                        placeholder=""
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className={styles.head}>Email </Form.Label>
                  <Form.Control
                    className={styles.label}
                    type="email"
                    placeholder=""
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className={styles.head}>Subject </Form.Label>
                  <Form.Control
                    className={styles.label}
                    type="text"
                    placeholder=""
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1">
                  <Form.Label className={styles.head}>Message</Form.Label>
                  <Form.Control as="textarea" rows={4} />
                </Form.Group>
                <div className="text-center">
                  <Button
                    className={styles.btn}
                    variant="primary"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      setVisible(false);
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default Header;

import { createContext, useContext, useState, useRef, useEffect } from "react";
export const BiddingId = createContext(null);
export const useChefInvite = () => useContext(BiddingId);

export const InviteChefProvider = ({ children }) => {
  const [chefdata, setChefdata] = useState(null);

  return (
    <BiddingId.Provider value={{ chefdata, setChefdata }}>
      {children}
    </BiddingId.Provider>
  );
};

import React, { useState } from "react";
import { Col, Row, Button, Modal } from "react-bootstrap";
import styles from "../../styles/modules/signupOption.module.scss";
import { useNavigate } from "react-router-dom";

function Example({ appear, setAppear }) {
  const history = useNavigate();
  const handleClose = () => {
    setAppear(false);
  };
  return (
    <>
      <Modal
        className={`${styles.modalBody} App`}
        size="lg"
        show={appear}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className={styles.content}>
          <Modal.Header closeButton>
            <Modal.Title
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "black",
                margin: "0px 38%",
              }}
            >
              Join for FREE!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col
                className={styles.colBody}
                xs={6}
                style={{
                  borderRight: "1px solid gray",
                  borderBottom: "1px solid gray",
                }}
              >
                <img src="images/img2.png" alt="" />
                <Button
                  onClick={() => {
                    handleClose();
                    history(`/signup/640ec4c5941ddd210c4dae93`);
                  }}
                >
                  Pastry Chef/Chefs & Cooks
                </Button>
              </Col>
              <Col
                className={styles.colBody}
                xs={6}
                style={{ borderBottom: "1px solid gray" }}
              >
                <img src="images/Img1.png" alt="" /> <br />
                <Button
                  onClick={() => {
                    handleClose();
                    history(`/signup/640ec4ec941ddd210c4dae9d`);
                  }}
                >
                  Tasters & Foodies
                </Button>
              </Col>
              <Col
                className={styles.colBody}
                xs={6}
                style={{
                  borderRight: "1px solid gray",
                  borderBottom: "1px solid gray",
                }}
              >
                <img src="images/img3.png" alt="" />

                <Button
                  onClick={() => {
                    handleClose();
                    history(`/signup/640ec4da941ddd210c4dae98`);
                  }}
                >
                  Delivery Drivers & Grocery Shoppers
                </Button>
              </Col>
              <Col
                className={styles.colBody}
                xs={6}
                style={{ borderBottom: "1px solid gray" }}
              >
                <img src="images/restaurant.png" alt="" />
                <Button
                  onClick={() => {
                    handleClose();
                    history(`/sign-up/640ec4f7941ddd210c4daea2`);
                  }}
                >
                  Restaurants & Catering Companies
                </Button>
              </Col>
              <Col
                className={styles.colBody}
                xs={6}
                style={{
                  margin: "0px 25% 40px",
                }}
              >
                <img src="images/grocery.png" alt="" />
                <Button
                  onClick={() => {
                    setAppear(false);
                    history(`/signup/640ec508941ddd210c4daea7`);
                  }}
                >
                  Groceries & Convenience Stores
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default Example;

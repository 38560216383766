import React, { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { onMessageListener } from "../../pages/firebase";
import { NotificationManager } from "react-notifications";

export default function Index() {
  const [notification, setNotification] = useState({ title: "", body: "" });

  useMemo(() => {
    if (notification?.title) {
      NotificationManager.success(notification?.body, notification?.title);
    }
    return () => {};
  }, [notification]);
  useMemo(() => {
    let interval = setInterval(() => {
      onMessageListener()
        .then((payload) => {
          setNotification({
            title: payload?.notification?.title,
            body: payload?.notification?.body,
          });
        })
        .catch((err) => console.log("failed: ", err));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });
  return <></>;
}
